import "./styles.css";
import React, { Fragment } from "react";

function InsaneDatePicker(props) {
  const [year, set_year] = React.useState("");
  const [month, set_month] = React.useState("");
  const [date, set_date] = React.useState("");
  const [hour, set_hour] = React.useState("");
  const [min, set_min] = React.useState("");

  const [VALUE, SET_VALUE] = React.useState(null);

  const inputDate = React.useRef();

  function setDisplayValue(date = new Date()) {
    let d = new Date(date);
    d.toISOString()
    let DD = d.getDate();
    let MM = d.getMonth() + 1;
    let YYYY = d.getFullYear();

    let HH = d.getHours() - 7;
    let mm = d.getMinutes();

    function zerobefore(n) { return (n > 9) ? (n) : ("0" + n) }

    set_year(YYYY);
    set_month(zerobefore(MM));
    set_date(zerobefore(DD));
    set_hour(zerobefore(HH));
    set_min(zerobefore(mm));

    // return yy + "-" + zerobefore(mm) + "-" + zerobefore(dd)
  }

  function validate(value, defaultvalue) {
    return ((value && value !== "") ? value : defaultvalue)
  }

  React.useEffect(() => {
    setDisplayValue(VALUE);
  }, [VALUE]);


  React.useEffect(() => {
    if (props.value) {
      setDisplayValue(props.value);
    }
  }, [props.value]);

  return (
    <div className={"container " + props.className}>

      <input
        ref={inputDate}
        className="select"
        type={props.type}
        defaultValue={VALUE}
        onChange={(event) => {
          SET_VALUE(event.target.value);
          props.change(event.target.value);
        }} />

      <div className="insane-date-picker" onClick={() => {inputDate.current.showPicker()}}>
        {
          (props.type === "date") ? (
            <Fragment>
              <span className="num">{validate(year, "YYYY")}</span>
              <span className="label">/</span>
              <span className="num">{validate(month, "MM")}</span>
              <span className="label">/</span>
              <span className="num">{validate(date, "DD")}</span>
            </Fragment>
          ) : (null)

            (props.type === "time" && props.type === "datetime-local") ? (
            <Fragment>
              <span className="num">{validate(hour, "HH")}</span>
              <span className="label">:</span>
              <span className="num">{validate(min, "mm")}</span>
            </Fragment>
          ) : (null)
        }
      </div>
    </div>
  );
}

export default InsaneDatePicker;
