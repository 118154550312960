import "./styles.css";
import React, { Fragment } from "react";
import {
  useLocation
} from "react-router-dom";

import { HttpRequest, HttpRequestForCSV } from "../../service/HttpRequest";

import Spinner from "../../component/spinner";
// import OpenStreetMap from "../../component/insane-openstreet-map";

import InsaneCollapseContent from "../../component/insane-collapse-content";
import InsaneDatePicker from "../../component/insane-date-picker";
// import InsaneRatioBar from "../../component/insane-ratio-bar";

import {
  useGlobalContext
} from "../../service/GlobalContext";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App() {

  const [globalState, setGlobalState] = useGlobalContext();

  const query = useQuery();
  const [loading, setLoading] = React.useState(false);
  const [loadingReport, setLoadingReport] = React.useState(false);

  const [state_filter, set_state_filter] = React.useState(false);

  const [REPORT_ID, SET_REPORT_ID] = React.useState(null);
  const [REPORT_NAME, SET_REPORT_NAME] = React.useState(null);

  // const [leyer_selected, setLeyer_selected] = React.useState('safety');
  const [search_date, set_search_date] = React.useState("");
  const [search_enddate, set_search_enddate] = React.useState("");

  const [search_sensor_selected, set_search_sensor_selected] = React.useState("161");
  const [sensor_selected, set_sensor_selected] = React.useState(null);

  const [DATA_SENSOR, SET_DATA_SENSOR] = React.useState(null);
  const [DATA_REPORT, SET_DATA_REPORT] = React.useState(null);

  const [CONDITION_REPORT, SET_CONDITION_REPORT] = React.useState(null);

  const [STATE_AUTO_REPORT, SET_STATE_AUTO_REPORT] = React.useState(false);

  function formatedate(date = new Date()) {
    let d = new Date(date);
    let dd = d.getDate();
    let mm = d.getMonth() + 1;
    let yy = d.getFullYear();

    function zerobefore(n) { return (n > 9) ? (n) : ("0" + n) }

    return yy + "-" + zerobefore(mm) + "-" + zerobefore(dd)
  }

  function downloadCSV(csvContent, fileName) {
    // console.log(csvContent);
    let encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link); // Required for FF

    link.click();
    // const blob = new Blob([csvContent], { type: "text/csv" })
    // // Create an anchor element and dispatch a click event on it
    // // to trigger a download
    // const a = document.createElement('a');
    // a.download = fileName + ".csv";
    // a.href = window.URL.createObjectURL(blob);
    // const clickEvt = new MouseEvent('click', {
    //   view: window,
    //   bubbles: true,
    //   cancelable: true,
    // })
    // a.dispatchEvent(clickEvt)
    // a.remove()
  }


  async function getSensor() {
    setLoading(true);

    await HttpRequest("/api/getsensor",
      {
        "sensor_id": null,
        "date": search_date,
        "peak_id": null,
        "province_id": null,
        "index:": null,
        "count": null,
        "sensor_types": null//["radar", "cctv", "bluetooth", "toll", "alpr", "loop"]
      }
    ).then((data) => {
      console.log("HttpRequest getsensor :", data);
      if (data.result === "OK") {
        SET_DATA_SENSOR(data.sensors);
      }
    });

    setLoading(false);
  }

  function getSensorReport(sensor_id) {

    setLoadingReport(true);

    HttpRequest("/api/getsensorreport",
      {
        sensor_id: (sensor_id ? sensor_id : search_sensor_selected),
        fromdate: search_date,
        todate: search_enddate,
        report_types: [REPORT_ID]
      }
    ).then((data) => {
      console.log("HttpRequest getsensorreport :", data);

      if (data.result === "OK") {
        SET_DATA_REPORT(data.data)
        // let zones = data.zones;
        // setDATA_SAFETY(zones);
      }


      setTimeout(() => {
        setLoadingReport(false);
      }, 1000);
    });

  }


  async function downloadSensorReport() {
    // setLoading(true);
    await HttpRequestForCSV("/api/downloadsensorreport ",
      {
        sensor_id: search_sensor_selected,
        fromdate: search_date,
        todate: search_enddate,
        report_types: [REPORT_ID]
      }
    ).then((data) => {
      // console.log("HttpRequest downloadsensorreport :", data);
      let fileName = ("Report_" + REPORT_ID + "_" + search_date + "_" + search_enddate);
      downloadCSV(data, fileName);
    });
  }

  function getColorStatus(status) {
    // console.log(status);
    if (CONDITION_REPORT) {
      let find_status = CONDITION_REPORT.filter((condition) => condition.status === status);
      if (find_status.length > 0) {
        return find_status[0].color;
      } else {
        return "#FFF"
      }
    } else {
      return "#FFF"
    }
  }

  function getColorWeekend(date_string) {
    var myDate = new Date(date_string);

    if (myDate.getDay() === 6) {
      // console.log(myDate.getDay())
      // return "#CFC3FF"
      return "#46e2fa"
    } else if (myDate.getDay() === 0) {
      // console.log(myDate.getDay())
      // return "#FFCAC3"
      return "#46e2fa"
    } else {
      return "#FFF"
    }
  }

  React.useEffect(() => {
    const controller = new AbortController();

    let current_date = new Date();

    let date_start = formatedate(current_date);
    let date_end = formatedate(current_date.setDate(current_date.getDate() + 1));

    set_search_date("2024-01-01");
    set_search_enddate(date_start);

    getSensor();

    return () => {
      controller.abort();
    };
  }, []);

  // React.useEffect(() => {
  //   const controller = new AbortController();

  //   if (globalState.enum) {
  //     // SET_CONDITION_REPORT(globalState.enum.condition_report);
  //   }

  //   return () => {
  //     controller.abort();
  //   };
  // }, [globalState.enum]);

  React.useEffect(() => {
    const controller = new AbortController();

    let report_id = query.get("report_id");
    let sensor_id = query.get("sensor_id");


    //console.log(report_id);

    // console.log(sensor_id);

    //SET_REPORT_ID(report_id);
    SET_REPORT_ID(report_id);
    SET_DATA_REPORT(null);

    // Add REPORT_NAME //
    if (globalState.enum) {
      SET_CONDITION_REPORT(globalState.enum.condition_report[report_id]);

      // console.log( globalState.enum.report_type)
      let find_report_name = globalState.enum.report_type.filter((type) => type.id === report_id);

      if (find_report_name.length > 0) {
        SET_REPORT_NAME(find_report_name[0].title);
      }
    }

    if (sensor_id) {
      set_search_sensor_selected(sensor_id);
    }

    return () => {
      controller.abort();
    };
  }, [query]);



  React.useEffect(() => {
    const controller = new AbortController();

    let sensor_id = query.get("sensor_id");


    if (sensor_id && !STATE_AUTO_REPORT) {
      if (search_sensor_selected && search_date && search_enddate && REPORT_ID) {
        getSensorReport(sensor_id);
        SET_STATE_AUTO_REPORT(true);
      }
    }

    return () => {
      controller.abort();
    };
  }, [search_sensor_selected, search_date, search_enddate, REPORT_ID]);



  React.useEffect(() => {
    const controller = new AbortController();

    // let report_id = query.get("report_id");
    // SET_REPORT_ID(report_id);
    // SET_DATA_SENSOR(null);

    if (CONDITION_REPORT) {
      console.log("CONDITION_REPORT ---> ", CONDITION_REPORT);
    }

    return () => {
      controller.abort();
    };
  }, [CONDITION_REPORT]);

  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_SENSOR) {
      // console.log(search_sensor_selected);
      let result = DATA_SENSOR.filter((s) => s.sensor_id === search_sensor_selected);
      // console.log(result);
      if (result.length > 0) {
        set_sensor_selected(result[0]);
      } else {
        set_sensor_selected(null);
      }
    }

    return () => {
      controller.abort();
    };
  }, [search_sensor_selected, DATA_SENSOR]);

  return (
    <>
      {loading ? (
        <div
          className="main-content"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Spinner></Spinner>
          <div style={{ color: "#000" }}>กำลังโหลด</div>
        </div>
      ) : (
        <div className="main-content">
          <div className="page-content">
            <InsaneCollapseContent title="FILTER" type="filter" color="#005baf" style={{ zIndex: 20 }} state={state_filter} stateChange={set_state_filter}>
              <div className="filter-content">
              <label className="input-label">ค้นหาข้อมูลวันที่</label>
                <input
                  type="date"
                  className="form-control page-input input-date-mobile"
                  value={search_date}
                  onChange={(event) =>
                    set_search_date(event.target.value)
                  }
                />

                <InsaneDatePicker
                  type="date"
                  className="form-control page-input input-date-web"
                  value={search_date}
                  change={set_search_date}
                />
                <label className="input-label">ถึงวันที่</label>
                <input
                  type="date"
                  className="form-control page-input input-date-mobile"
                  value={search_enddate}
                  onChange={(event) =>
                    set_search_enddate(event.target.value)
                  }
                />

                <InsaneDatePicker
                  type="date"
                  className="form-control page-input input-date-web"
                  value={search_enddate}
                  change={set_search_enddate}
                />
                
                <select
                  className="form-control page-input"
                  value={search_sensor_selected}
                  placeholder="Select Sensor"
                  onChange={(event) =>
                    set_search_sensor_selected(event.target.value)
                  }
                >
                  {
                    DATA_SENSOR ? (
                      DATA_SENSOR.map((sensor, index) => {
                        return (<option key={index} value={sensor.sensor_id}>{sensor.name}</option>);
                      })
                    ) : (null)
                  }
                </select>

                <button className="bt-search-filter" onClick={() => { getSensorReport(); set_state_filter(false); }}>ค้นหา</button>
                <button className="bt-search-filter" onClick={() => { downloadSensorReport(); }}>ดาวน์โหลด</button>

                <div style={{ flexGrow: 1 }}></div>
                {
                  (CONDITION_REPORT && CONDITION_REPORT.length > 0) ? (
                    <div className="legend-label-container" style={{ marginBottom: 10 }}>
                      <div className="head-label">{REPORT_NAME}</div>
                      {
                        CONDITION_REPORT.map((label, index) => {
                          return (
                            <div className="legend" key={index}>
                              <div className="color" style={{
                                background: label.color ? label.color : "#444",
                                minHeight: label.size ? "unset" : 20,
                                height: label.size ? parseInt(label.size) : 20
                              }}></div>
                              <div className="text">{label.title}</div>
                            </div>
                          );
                        })
                      }
                    </div>
                  ) : (null)
                }

              </div>
            </InsaneCollapseContent>

            <div className="content setting-page">

              {
                loadingReport ? (
                  <div
                    className="main-content"
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Spinner></Spinner>
                    <div style={{ color: "#000" }}>กำลังโหลด</div>
                  </div>
                ) : (

                  <>
                    <div className="display-control-contianer" style={{ display: "flex" }}>
                      <div className="contianer" onClick={() => (state_filter) ? set_state_filter(false) : set_state_filter(true)}>

                        <div className="title title-report" style={{ background: "#96cdff" }}>{REPORT_NAME}</div>


                        {
                          sensor_selected ? (
                            <div className="title title-report" style={{ background: "lightblue" }}>{sensor_selected.name}</div>
                          ) : (null)
                        }

                        <div className="title">{search_date}</div> -
                        <div className="title">{search_enddate}</div>
                      </div>
                    </div>

                    <div className="content-report">
                      {
                        DATA_REPORT ? (
                          <table className="table-report" border="0" cellSpacing="-1" cellPadding="0">
                            <thead style={{ position: "sticky", zIndex: 10, top: 0, left: 0, background: "white" }}>
                              <tr>
                                <th rowSpan={3} style={{ minWidth: 80, position: "sticky", zIndex: 10, top: 0, left: 0, background: "white" }}>
                                  <div className="bg-cell-table-report" style={{height: 60}}>วันที่</div>
                                </th>
                                <th colSpan={DATA_REPORT.reports[0].headers.length} style={{ position: "sticky", zIndex: 9, top: 0 }}>
                                  <div className="bg-cell-table-report" style={{ position: "absolute" }}></div>
                                  <div style={{ position: "sticky", zIndex: 9, top: 0, left: "calc(50% + 20px)", width: "fit-content" }}>เวลา</div>
                                </th>
                              </tr>
                              <tr>
                                {
                                  DATA_REPORT.reports[0].headers.map((time, i) => {
                                    return ((i % 4) === 0) ? (<th key={i} colSpan={4} style={{ fontSize: 10, position: "sticky", zIndex: 9, top: 0 }}>
                                      <div className="bg-cell-table-report">{time.x_label}</div>
                                    </th>) : (null)
                                  })
                                }
                              </tr>
                              <tr>
                                {
                                  DATA_REPORT.reports[0].headers.map((time, i) => {
                                    return <th key={i} style={{ fontSize: 10, position: "sticky", zIndex: 9, top: 0 }}><div className="bg-cell-table-report"></div></th>
                                  })
                                }
                              </tr>
                            </thead>
                            <tbody>
                              {
                                DATA_REPORT.reports[0].rows.map((row, i) => {
                                  return <tr key={i}
                                    style={{ border: "2px solid" + (getColorWeekend(row.date) !== "#FFF" ? "#46e2fa" : null) }}>
                                    <td style={{ fontSize: 10, position: "sticky", zIndex: 8, left: 0 }}>
                                      <div
                                        className="bg-cell-table-report"
                                        style={{
                                          background: getColorWeekend(row.date),
                                          border: (getColorWeekend(row.date) !== "#FFF" ? "none" : null)
                                        }}>
                                        {row.date}
                                      </div>
                                    </td>
                                    {
                                      row.values.map((value, j) => {
                                        return <td key={j} title={value.y_label} style={{ background: getColorStatus(value.y_status) }}></td>
                                      })
                                    }
                                  </tr>
                                })
                              }
                            </tbody>
                          </table>
                        ) : (null)
                      }


                    </div>
                  </>

                )
              }

            </div>

            <div className="content-mobile"></div>

          </div>
        </div>
      )}
    </>
  );
}

export default App;
