import "./styles.css";
import React from "react";
import { useNavigate } from "react-router-dom";

import menuIcon from "../../assets/icons/menu.svg";
import closeIcon from "../../assets/icons/close.png";

import logoutIcon from "../../assets/icons/logout.svg";
import notiIcon from "../../assets/icons/noti.svg";

import banner_desktop from "../../assets/images/ITS Banner 2320x120.png";
import banner_mobile from "../../assets/images/ITS Banner 1160x120.png";

import { HttpRequest } from "../../service/HttpRequest";
import { deleteCookie } from "../../service/CookieManagement";
// import { useAuth } from "../service/Authentication";

import {
  useGlobalContext,
  setAuthentication
} from "../../service/GlobalContext";

import { getRoutes } from "../../routing/routes";

import Spinner from "../spinner";



async function checkSession() {
  return await HttpRequest("/api/checksession", {}).then((data) => {
    // console.log("HttpRequest /api/checksession:", data);

    if (data.result === "OK") {
      if (data.session.logout === false) {
        return {
          token: data.session.sessiontoken,
          user: data.user,
          permission: data.permission
        }
      } else {
        return {
          token: "",
          user: "",
          permission: data.permission
        }
      }
    } else {
      return {
        token: "",
        user: "",
        permission: data.permission
      }
    }

  });
}


function Navbar() {
  const [globalState, setGlobalState] = useGlobalContext();

  // let menuList = props.menuList;
  // let dropdownList = props.dropdownList;

  let current_route = window.location.pathname;

  const [RouteList, setRouteList] = React.useState(null);

  const [menuList, setMenuList] = React.useState([]);
  const [dropdownList, setDropdownList] = React.useState([]);

  const [stateMenu, setStateMenu] = React.useState(false);
  const [stateNotiList, setStateNotiList] = React.useState(false);

  const [username, setUsername] = React.useState(null);

  const [notiList, setNotiList] = React.useState([]);
  const [notiAlert, setAlert] = React.useState(false);

  // const [timerRefresh, setTimerRefresh] = React.useState(0);

  // const AuthContext = useAuth();
  const navigate = useNavigate();

  const onLogOut = () => {
    // let usertoken = getCookie("eec_dashboard_login_token");
    // console.log(AuthContext.user);

    // console.log(globalState);
    HttpRequest("/api/logout", {}).then(async (data) => {
      console.log(data);
      if (data.result === "OK") {
        // deleteCookie("eec_dashboard_login_user");
        // console.log(globalState);
        deleteCookie(globalState.config_app.cookie_key_session_token);

        let sesssion_data = await checkSession();
        setAuthentication(setGlobalState, sesssion_data);
        // setAuthentication(setGlobalState, null);

        navigate("/");
        setStateMenu(false);
      }
    });
  };

  async function onGetNotification(state = "") {
    // let param = {
    //   latitude: 13.84283,
    //   longitude: 100.67356,
    //   distancekm: 2000,
    //   starttime: null,
    //   stoptime: null,
    //   index: null,
    //   count: null,
    // };

    // await HttpRequest("/admin/getrecentfallingdetectionlist", param).then(
    //   (data) => {
    //     console.log("HttpRequest getrecentfallingdetectionlist :", data);
    //     if (data.result === "OK") {
    //       setNotiList(data.getrecentfallingdetectionlist);
    setAlert(true);
    //     }
    //   }
    // );
  }

  const onNavigatePage = (path) => {
    // console.log("onNavigatePage : ", path);
    navigate(path);
    setStateMenu(false);
  };

  const onReadAllNoti = () => {
    let _notis = [...notiList];
    for (let i = 0; i < _notis.length; i++) {
      _notis[i].read = true;
    }
    setNotiList(_notis);
  };

  const onReadByIndex = (index) => {
    let _notis = [...notiList];
    _notis[index].read = true;
    setNotiList(_notis);
  };

  // const onGetNotiCount = () => {
  //   // let countNoRead = notiList.filter((noti) => noti.read === false);
  //   // return countNoRead.length;
  //   return 1
  // };

  const getTimeWording = (time) => {
    let startDate = new Date(time);
    // Do your operations
    let endDate = new Date();
    let seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    let minute = seconds / 60;
    let hour = minute / 60;
    let day = hour / 24;

    if (hour > 23) {
      return "เมื่อ " + parseInt(day) + " วันที่แล้ว";
    } else if (minute > 59) {
      return "เมื่อ " + parseInt(hour) + " ชั่วโมงที่แล้ว";
    } else if (seconds > 59) {
      return "เมื่อ " + parseInt(minute) + " นาทีที่แล้ว";
    } else {
      return "เมื่อสักครู่นี้";
    }
  };

  const twoDigit = (n) => {
    let number = parseInt(n);
    if (number <= 9) {
      return "0" + number;
    } else {
      return "" + number;
    }
  };

  const ConvertDate = (dateString, type = "datetime") => {
    if (dateString) {
      let date = new Date(dateString);
      if (type === "datetime") {
        return (
          date.getFullYear() +
          "-" +
          twoDigit(date.getMonth() + 1) +
          "-" +
          twoDigit(date.getDate()) +
          " " +
          twoDigit(date.getHours()) +
          ":" +
          twoDigit(date.getMinutes()) +
          ":" +
          twoDigit(date.getSeconds())
        );
      }
      if (type === "date") {
        return (
          date.getFullYear() +
          "-" +
          twoDigit(date.getMonth() + 1) +
          "-" +
          twoDigit(date.getDate())
        );
      }
      if (type === "time") {
        return (
          twoDigit(date.getHours()) +
          ":" +
          twoDigit(date.getMinutes()) +
          ":" +
          twoDigit(date.getSeconds())
        );
      }
    } else {
      return "";
    }
  };

  React.useEffect(() => {
    const controller = new AbortController();

    // console.log(routes);
    if (RouteList && globalState.enum) {
      let get_menu_list = RouteList.filter((route) => route.path === "/");
      let menu_list = [];
      //console.log('menu_list', menu_list);

      if (get_menu_list.length > 0) {

        for (let i = 0; i < get_menu_list[0].children.length; i++) {
          let menu = get_menu_list[0].children[i];
          if (menu.enable === true) {
            menu_list.push({ path: menu.path, displayName: menu.displayName });
          }
        }

        let findRouteReport = get_menu_list[0].children.filter((children) => children.path === "report");

        if (findRouteReport.length > 0) {
          let _dropdown_menu = [
            { name: "report", displayName: "REPORT", path: "report", enable: true, routeList: [] }
          ];

          for (let i = 0; i < globalState.enum.report_type.length; i++) {
            const _report = globalState.enum.report_type[i];

            _dropdown_menu[0].routeList.push(
              {
                //path: ("report?report_id=" + _report.id),
                path: ("report?report_id=" + _report.id),
                name: ("REPORT_" + _report.id),
                displayName: (_report.title),
                enable: true
              }
            )
          }
          setDropdownList(_dropdown_menu);

        } else {
          setDropdownList([]);
        }

      }

      setMenuList(menu_list);

      // console.log(RouteList);


    }

    return () => {
      controller.abort();
    };
  }, [RouteList, globalState.enum]);


  // React.useEffect(() => {
  //   const controller = new AbortController();

  //   console.log("username : ", username);

  //   return () => {
  //     controller.abort();
  //   };
  // }, [username]);


  React.useEffect(() => {
    const controller = new AbortController();

    onGetNotification();

    let routes = null;

    // console.log(globalState.auth);

    if (globalState.auth) {

      if (globalState.auth.user === "") {
        setUsername("");
      } else if (globalState.auth.user.username) {
        setUsername(globalState.auth.user.username);
      }

      routes = getRoutes(globalState.auth);
    } else {
      setUsername(null);
      routes = getRoutes();
    }

    setRouteList(routes);

    return () => {
      controller.abort();
    };
  }, [globalState.auth]);



  // React.useEffect(() => {
  //   const controller = new AbortController();

  //   // console.log("timerRefresh : ", timerRefresh);

  //   // if (timerRefresh == 60) {
  //   //   setTimerRefresh(0);

  //   //   onGetNotification();
  //   // }

  //   console.log(current_route);

  //   return () => {
  //     controller.abort();
  //   };
  // }, [current_route]);

  // React.useEffect(() => {
  //   const RefreshInternal = setInterval(() => {
  //     setTimerRefresh((t) => t + 1);
  //   }, 1000);
  //   return () => {
  //     clearInterval(RefreshInternal);
  //   };
  // }, []);

  return (
    <div className="navbar-container">
      {/* <div className="container-a">
        <div className="user-name"></div>
        <div style={{ flexGrow: 1 }}></div>
        <div className="btn-logout" onClick={() => onLogOut()}>
          <span>Logout</span>
          <img className="btn-icon" src={logoutIcon} />
        </div>
      </div> */}

      <div className="banner-container desktop">
        <img src={banner_desktop} />
      </div>

      <div className="banner-container mobile">
        <img src={banner_mobile} />
      </div>

      <div className="container-b">
        {/* <div className="title" onClick={() => onNavigatePage("/dashboard")}>

          <span style={{ marginRight: 8, color: "#FFFFFF" }}>EEC</span>
          <span style={{ marginRight: 8, color: "#96CDFF" }}>Dashboard</span>
          

        </div> */}
        {/* <div style={{flexGrow: 1}}></div> */}

        <div className="menu-container web">
          {menuList.map((menu, index) => {
            // console.log('menu ', menu);
            return (
              <div
                className={"menu-button " + (current_route === ("/" + menu.path) ? "active" : "")}
                key={index}
                onClick={() => onNavigatePage(menu.path)}
              >
                <span className="name">{menu.displayName}</span>
                <div className="bar"></div>
              </div>
            );
          })}


          {dropdownList.map((menu, index) => {
            return (
              <div
                className={"menu-button dropdown " + (current_route === ("/" + menu.path) ? "active" : "")}
                key={index}
              // onClick={() => onNavigatePage(menu.path)}
              >
                <span className="name">{menu.displayName}</span>
                <div className="bar"></div>

                <div className="dropdown-content">
                  {
                    menu.routeList.map((list, jndex) => {
                      return <div className={"list "} key={jndex} onClick={() => onNavigatePage(list.path)}>{list.displayName}</div>
                    })
                  }
                </div>
              </div>
            );
          })}

        </div>

        <div style={{ flexGrow: 1, padding: "0px 10px" }}>
          <div className="current-page-mobile">
            {
              menuList.map((menu, jndex) => {
                return (current_route === ("/" + menu.path)) ? (<div className="list" key={jndex}>{menu.displayName}</div>) : (null)
              })
            }
            {
              dropdownList.map((menu, jndex) => {
                return (current_route === ("/" + menu.path)) ? (<div className="list" key={jndex}>{menu.displayName}</div>) : (null)
              })
            }
          </div>
        </div>

        <div className="menu-container web" style={{ flexGrow: "unset", padding: 20 }}>
          {
            username || username === "" ? (
              (username !== "") ? (
                <div style={{ fontWeight: "bolder" }}>{username}</div>
              ) : (
                <div className="btn btn-light" onClick={() => onNavigatePage("login")}>
                  Login
                </div>
              )
            ) : (
              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <Spinner></Spinner>
                <span>Authenticating...</span>
              </div>
            )
          }
        </div>

        <div onClick={() => setStateNotiList(true)} className="noti-button">
          {/* {onGetNotiCount() > 0 ? (
            <div className="noti-pop"> {onGetNotiCount()} </div>
          ) : null} */}

          {notiAlert ? <div className="noti-pop"> ! </div> : null}

          <img className="btn-icon" src={notiIcon} />
        </div>


        <div onClick={() => setStateMenu(true)} className="side-menu-button">
          <img className="btn-icon" src={menuIcon} />
        </div>
      </div>


      <div className={"side-nav " + (stateMenu ? "active" : null)}>

        <div className="side-nav-title-container">
          <div className="side-nav-title">
            {
              username || username === "" ? (
                (username !== "") ? (
                  <div>
                    <span style={{ fontSize: 12 }}>Welcome</span> <br />
                    <span style={{ fontWeight: "bolder" }}>{username}</span>
                  </div>
                ) : (
                  <div className="btn btn-light" onClick={() => onNavigatePage("login")}>
                    Login
                  </div>
                )
              ) : (
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <Spinner></Spinner>
                  <span>Authenticating...</span>
                </div>
              )
            }
          </div>

          <button className="btn btn-light" onClick={() => setStateMenu(false)}>
            <img className="btn-icon" src={closeIcon} />
          </button>
        </div>

        <div className="side-nav-menu-container">
          {menuList.map((menu, index) => {
            return (
              <div
                className={"side-nav-menu-button " + (current_route === ("/" + menu.path) ? "active" : "")}
                key={index}
                onClick={() => { onNavigatePage(menu.path); setStateMenu(false) }}
              >
                <span className="name">{menu.displayName}</span>
              </div>
            );
          })}

          {dropdownList.map((menu, index) => {
            return (
              <div
                className={"side-nav-menu-dropdown " + (current_route === ("/" + menu.path) ? "active" : "")}
                key={index}
              // onClick={() => onNavigatePage(menu.path)}
              >
                <span className="name">{menu.displayName}</span>
                <div className="dropdown-content">
                  {
                    menu.routeList.map((list, jndex) => {
                      return <div className="list" key={jndex} onClick={() => onNavigatePage(list.path)}>{list.displayName}</div>
                    })
                  }
                </div>
              </div>
            );
          })}

          <div style={{ flexGrow: 1 }}></div>
          {
            username ? (
              <div className="side-nav-menu-button"
                onClick={() => onLogOut()}
                style={{ color: "darkorange", boxShadow: "inset 0px 10px 20px -20px rgba(0, 0, 0, 0.75)", position: "sticky", bottom: 0 }}>
                <img className="btn-icon" src={logoutIcon} />
                <span className="name" style={{ marginLeft: 10, fontWeight: "bolder", letterSpacing: 5 }}>LOGOUT</span>
              </div>
            ) : (null)
          }
        </div>

      </div>

      <div className={"side-nav " + (stateNotiList ? "active" : null)}>
        <div className="side-nav-title-container">
          <div className="side-nav-title">แจ้งเตือน</div>
          <button
            className="btn btn-light"
            onClick={() => {
              setStateNotiList(false);
              setAlert(false);
              //   onReadAllNoti();
            }}
          >
            <img className="btn-icon" src={closeIcon} />
          </button>
        </div>

        <div className="side-nav-menu-container">
          {/* {notiList.map((noti, index) => {
            return ( */}
          <div
            className="noti-list"
          // key={index}
          // onClick={() => onReadByIndex(index)}
          >
            {/* <div className="noti-list-time">
                  {noti.read === false ? (
                    <span className="remark-no-read"></span>
                  ) : null}
                  <span>{getTimeWording(noti.time)} </span>
                </div> */}
            <div className="noti-list-row">
              <div className="toppic">หัวข้อ :</div>
              <div className="value danger">แจ้งเตือน</div>
            </div>
            <div className="noti-list-row">
              <div className="toppic">Detail :</div>
              <div className="value">รถบรรทุกวิ่งเกินครวามเร็ว</div>
            </div>
            <div className="noti-list-row">
              <div className="toppic">time :</div>
              <div className="value">01-01-2024 15:00:00</div>
            </div>
          </div>
          {/* );
          })} */}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
