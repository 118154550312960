import React from "react";
import { Chart } from "react-google-charts";

function App({ data, options, chartEvents }) {


    return (
        <Chart
            chartType="BarChart"
            width="100%"
            height="100%"
            data={data}
            options={options}
            chartEvents={chartEvents}
        />
    );
}

export default App;
