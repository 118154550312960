import "./styles.css";
import React from "react";
import { HttpRequest } from "../../service/HttpRequest";

function InsaneInputSearch(props) {
  const [textSearch, setTextSearch] = React.useState("");
  const [resultSearch, setResultSearch] = React.useState([]);
  const [resultState, setResultState] = React.useState(false);

  const [resultValue, setResultValue] = React.useState(null);

  async function onSearch(text = textSearch) {

    setResultSearch([]);

    await HttpRequest(props.api, { ...props.param, search: text }).then(
      (data) => {
        console.log(data);
        let key_result = Object.keys(data);
        if (data.result === "OK") {
          setResultSearch(data[key_result[1]]);

          if (data[key_result[1]].length > 0) {
            setResultState(true);
          }
        }
      }
    );
  }

  const onSetSelectValue = (list) => {
    setResultValue(list[props.attDisplay]);
    if (props.attValue || props.attValue !== "") {
      props.change(list[props.attValue]);
    } else {
      props.change(list);
    }

    setResultState(false);
  };

  const onClearValue = () => {
    setTextSearch("");
    setResultValue("");
    props.change("");

    setResultState(false);
  };

  const onToggle = () => {
    if (resultState) {
      setResultState(false);
    } else {
      setResultState(true);
      onSearch();
    }
  };

  React.useEffect(() => {
    // console.log(textSearch);
    if (textSearch && textSearch !== "") {
      onSearch(textSearch);
      setResultState(true);
    }
    if (resultValue === null) {
      if (props.attValue || props.attValue !== "") {
        setResultValue(props.value);
      } else {
        setResultValue(props.value[props.attDisplay]);
      }
    }
  }, [textSearch]);

  return (
    <div className="container">
      <div className="insane-input-search" onClick={() => onToggle()} >
        <nobr style={{ position: "absolute" }}>{resultValue ? resultValue : props.label}</nobr>
      </div>
      <div className={"select-content " + (resultState ? "active" : "")} >
        <div className="header">
          <input
            className="input"
            type={props.type}
            placeholder={props.placeholder}
            value={textSearch}
            onChange={(event) => setTextSearch(event.target.value)}
          />
          {/* <button
            className="bt"
            style={{ background: "#6c757d" }}
            onClick={() => onClearValue()}
          >
            C{" "}
          </button> */}
          <button
            className="bt"
            style={{ background: "#dc3545" }}
            onClick={() => setResultState(false)}
          >
            {" "}
            X{" "}
          </button>
        </div>

        <div
          className="list"
          onClick={() => onClearValue()}
        >
          ทั้งหมด
        </div>

        {resultSearch.map((searchList, index) => {
          return (
            <div
              className="list"
              key={index}
              onClick={() => onSetSelectValue(searchList)}
            >
              {searchList[props.attDisplay]}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default InsaneInputSearch;
