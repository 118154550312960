import "./styles.css";
import React, { Fragment } from "react";
import Spinner from "../../component/spinner";
import TAB_1 from "./tab1";
// import TAB_2 from "./tab2";
// import TAB_3 from "./tab3";

import TAB_2_3 from "./tab_2_3";

import IconDownload from "../../assets/icons/download-icon.png";
import { HttpRequest, HttpRequestForCSV } from "../../service/HttpRequest";


function App() {

  const [loading, setLoading] = React.useState(false);

  const [mostTrafficData, setMostTrafficData] = React.useState([]); //State for mostTrafficData data
  const [mostIntersectsData, setMostIntersectsData] = React.useState([]); // State for mostIntersectsData data
  const TABS = [
    { id: "23", title: "ช่วงถนนและแยกที่ติดขัดประจำ", element: <TAB_2_3 setMostTrafficData={setMostTrafficData} setMostIntersectsData={setMostIntersectsData} /> },
    { id: "1", title: "การเดินทางขนส่งสินค้าในพื้นที่ EEC", element: <TAB_1 /> },

    // { id: "2", title: "ช่วงถนนที่ติดขัดประจำ", element: <TAB_2 /> },
    // { id: "3", title: "ทางแยกที่ติดขัดประจำ", element: <TAB_3 /> },

  ]

  const [TABS_SELECTED, SET_TABS_SELECTED] = React.useState("23");
  const [TABS_SELECTED_STATE, SET_TABS_SELECTED_STATE] = React.useState(false);

  function toggleTabSelectOption() {
    if (TABS_SELECTED_STATE) {
      SET_TABS_SELECTED_STATE(false);
    } else {
      SET_TABS_SELECTED_STATE(true);
    }
  }

  function getTitleTab(id) {
    let result = TABS.filter((e) => e.id === id);
    if (result.length > 0) {
      return result[0].title;
    } else {
      return "";
    }
  }


  function downloadCSV(csvContent, fileName) {
    //console.log(csvContent);
    let encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  async function downloadRoad1() {
    await HttpRequestForCSV("/api/downloadfrequentjamlink",
      {

        "date": "2024-09-15",
        "peak_id": "ALLDAY",
        "period_id": "1M",
        "province_id": 0,

      }
    ).then((data) => {
      // console.log("HttpRequest downloadmostlink :", data);
      let fileName = ("FrequentJamLink_" + "2024-09-15" + "_" + "ALLDAY");
      downloadCSV(data, fileName);
    });
  }

  async function downloadRoad2() {
    await HttpRequestForCSV("/api/downloadfrequentjamintersect",
      {

        "date": "2024-09-15",
        "peak_id": "ALLDAY",
        "period_id": "1M",
        "province_id": 0,

      }
    ).then((data) => {
      // console.log("HttpRequest downloadmostlink :", data);
      let fileName = ("FrequentJamIntersect_" + "2024-09-15" + "_" + "ALLDAY");
      downloadCSV(data, fileName);
    });
  }

  // function download 2_3 for test
  /* function handleDownload() {
     // Check if the data is available
     if (mostTrafficData.length === 0 && mostIntersectsData.length === 0) {
       alert("No data available to download");
       return;
     }
 
     //  mostTrafficData
     const trafficHeaders = ["link_id", "label", "province_name", "meter_start", "meter_end"];
     const trafficCsvContent = mostTrafficData.map(road => ({
       link_id: road.link_id || '',
       label: road.label || '',
       province_name: road.province_name || '',
       meter_start: road.meter_start || '',
       meter_end: road.meter_end || ''
     }));
 
     //  mostIntersectsData
     const intersectsHeaders = ["intersect_id", "label", "trafficlight", "lat", "lon"];
     const intersectsCsvContent = mostIntersectsData.map(intersect => ({
       intersect_id: intersect.intersect_id || '',
       label: intersect.label || '',
       trafficlight: intersect.trafficlight || '',
       lat: intersect.lat || '',
       lon: intersect.lon || ''
     }));
 
     // Convert mostTrafficData to CSV string
     const trafficCsvHeader = trafficHeaders.join(",") + "\n";
     const trafficCsvBody = trafficCsvContent.map(row =>
       `${row.link_id},${row.label},${row.province_name},${row.meter_start},${row.meter_end}`
     ).join("\n");
 
     // Convert mostIntersectsData to CSV string
     const intersectsCsvHeader = intersectsHeaders.join(",") + "\n";
     const intersectsCsvBody = intersectsCsvContent.map(row =>
       `${row.intersect_id},${row.label},${row.trafficlight},${row.lat},${row.lon}`
     ).join("\n");
 
     // Group to CSV 
     const csvContent = `Most Traffic Data:\n${trafficCsvHeader}${trafficCsvBody}\n\nMost Intersections Data:\n${intersectsCsvHeader}${intersectsCsvBody}`;
 
     // Create a blob for the CSV file and trigger the download
     const blob = new Blob([`\uFEFF${csvContent}`], { type: 'text/csv;charset=utf-8;' }); // format thai 
     const url = window.URL.createObjectURL(blob);
     const link = document.createElement("a");
     link.href = url;
     link.download = "traffic_data.csv"; // file name
     link.click();
 
     // Clean up the URL object
     window.URL.revokeObjectURL(url);
   }*/

  return (
    <>
      {loading ? (
        <div
          className="main-content"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Spinner></Spinner>
          <div style={{ color: "#000" }}>กำลังโหลด</div>
        </div>
      ) : (
        <div className="main-content">

          <div className="page-content">
            <div className="content dashboard-page">
              <div className="tab-container mobile"
                style={{ justifyContent: "center" }}
                onClick={() => toggleTabSelectOption()}>
                {getTitleTab(TABS_SELECTED)}
              </div>
              <div className={"tab-container collapse-mobile " + (TABS_SELECTED_STATE ? "active" : "")} style={{ display: "flex" }} >
                {
                  TABS.map((tab, index) => (

                    <div
                      key={index}
                      className={"tab-button " + (TABS_SELECTED === tab.id ? "active" : "")}
                      onClick={() => {
                        SET_TABS_SELECTED(tab.id);
                        SET_TABS_SELECTED_STATE(false);
                      }}>
                      {tab.title} <img src={IconDownload} alt="Icon-download" className="tap-button-icon-load" />
                    </div>

                  ))
                }
                {
                  TABS_SELECTED === "23" ? (
                    <div className="download">
                      <button onClick={downloadRoad1} style={{ width: '200px' }}>ดาวน์โหลดช่วงถนน</button>
                      &nbsp;
                      <button onClick={downloadRoad2} style={{ width: '200px' }}>ดาวน์โหลดทางแยก</button>
                      &nbsp;

                    </div>
                  ) : (
                    (null)
                  )
                }

              </div>
              {
                TABS.map((tab, index) => (
                  <Fragment key={index}>
                    {(TABS_SELECTED === tab.id) ? (tab.element) : (null)}
                  </Fragment>
                ))
              }
            </div>
            <div className="content-mobile"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
