import "./styles.css";
import React, { Fragment } from "react";
import { HttpRequest, HttpRequestForCSV } from "../../service/HttpRequest";
import OpenStreetMap from "../../component/insane-openstreet-map";
// import GaugeChart from '../../component/chart-gauge';
// import Noimg from '../../assets/images/noimg.png';

import { useGlobalContext } from "../../service/GlobalContext";

import Spinner from "../../component/spinner";


import WKX from "wkx";

import { Chart } from "react-google-charts";

import iconPinPort from "../../assets/icons/transport - port.png";
import iconPinAirport from "../../assets/icons/transport - airport.png";
import iconPinBus from "../../assets/icons/transport - bus.png";
import iconPinIndustrial from "../../assets/icons/transport - industrial.png";
import iconPinTrain from "../../assets/icons/transport - train.png";
import { colors } from "@material-ui/core";


function App() {

  const [globalState, setGlobalState] = useGlobalContext();

  const [loadingMap, setLoadingMap] = React.useState(true);

  const [search_date, set_search_date] = React.useState("");
  const [search_time, set_search_time] = React.useState("ALLDAY");
  const [search_province, set_search_province] = React.useState("");
  const [search_month, set_search_month] = React.useState("1M");

  const [legendLabel, setLegendLabel] = React.useState([]);

  const [ENUM, SET_ENUM] = React.useState(null);
  const [PEAKOPTION, SET_PEAKOPTION] = React.useState([]);
  const [PROVINCES, SET_PROVINCES] = React.useState([]);
  const [FILTER_TRAFFIC_OPTION, SET_FILTER_TRAFFIC_OPTION] = React.useState([]);
  const [PERIODOPTION, SET_PERIODOPTION] = React.useState([]);

  const [TRAFFIC_MAP, SET_TRAFFIC_MAP] = React.useState([]);
  const [PIN_MAP, SET_PIN_MAP] = React.useState([]);
  const [POLYGON_MAP, SET_POLYGON_MAP] = React.useState([]);

  const [DATA_TRAFFIC, SET_DATA_TRAFFIC] = React.useState(null);
  const [DATA_MAP_LINK, SET_DATA_MAP_LINK] = React.useState(null);

  const [DATA_ZONE, SET_DATA_ZONE] = React.useState(null);
  const [DATA_OD, SET_DATA_OD] = React.useState(null);

  const [DATA_CHART_BAR, SET_DATA_CHART_BAR] = React.useState(null);
  const [DATA_CHART_PIE, SET_DATA_CHART_PIE] = React.useState(null);


  const [DATA_PLACE, SET_DATA_PLACE] = React.useState(null);

  const [DATA_MAP_INDUSTRIAL, SET_DATA_MAP_INDUSTRIAL] = React.useState([]);
  const [DATA_MAP_PORT, SET_DATA_MAP_PORT] = React.useState([]);
  const [DATA_MAP_AIRPORT, SET_DATA_MAP_AIRPORT] = React.useState([]);
  const [DATA_MAP_BUS, SET_DATA_MAP_BUS] = React.useState([]);
  const [DATA_MAP_TRAIN, SET_DATA_MAP_TRAIN] = React.useState([]);

  const [DISPLAY_MAP_INDUSTRIAL, SET_DISPLAY_MAP_INDUSTRIAL] = React.useState(false);
  const [DISPLAY_MAP_PORT, SET_DISPLAY_MAP_PORT] = React.useState(false);
  const [DISPLAY_MAP_AIRPORT, SET_DISPLAY_MAP_AIRPORT] = React.useState(false);
  const [DISPLAY_MAP_BUS, SET_DISPLAY_MAP_BUS] = React.useState(false);
  const [DISPLAY_MAP_TRAIN, SET_DISPLAY_MAP_TRAIN] = React.useState(false);

  const [mapSettingDefault, setMapSettingDefault] = React.useState({
    center: [13.762201991756932, 100.50106010074637],
    zoom: 10,
    raduis_m: 1000
  });

  // const [ROUTE_SELECTED, SET_ROUTE_SELECTED] = React.useState(null);

  const [RANGE_TIME_DATA, SET_RANGE_TIME_DATA] = React.useState(null);
  const [STATE_CONTENT_CHART, SET_STATE_CONTENT_CHART] = React.useState(null);

  async function getTraffic() {

    await HttpRequest("/api/getlink",
      {
        "center_lat": 13.28772312454414,
        "center_lon": 101.32467877424523,
        "raduis_m": 1000,
        "date": search_date,
        "peak_id": search_time,
        "province_id": search_province,
        "period_id": search_month,
        "vehicle_category": "truck",
        "index": 0,
        "count": 0,
        "wkb": true
      }
    ).then((data) => {
      console.log("HttpRequest getTraffic :", data);
      if (data.result === "OK") {

        for (let i = 0; i < data.links.length; i++) {
          let link = data.links[i];
          let decode = Buffer.from(link.wkb, 'base64');
          link.geojson = WKX.Geometry.parseTwkb(decode).toGeoJSON();
        }

        SET_DATA_TRAFFIC(data);
        SET_DATA_MAP_LINK(data.links);
        setLegendLabel(data.condition_volume);
        SET_RANGE_TIME_DATA(data.range_data)

      }
    });
  }

  function formatedate(date = new Date()) {

    let d = new Date(date);
    let dd = d.getDate();
    let mm = d.getMonth() + 1;
    let yy = d.getFullYear();

    function zerobefore(n) { return (n > 9) ? (n) : ("0" + n) }

    return yy + "-" + zerobefore(mm) + "-" + zerobefore(dd);

  }

  function formatedateReverse(date = new Date()) {

    let d = new Date(date);
    let dd = d.getDate();
    let mm = d.getMonth() + 1;
    let yy = d.getFullYear();

    function zerobefore(n) { return (n > 9) ? (n) : ("0" + n) }

    return zerobefore(dd) + "-" + zerobefore(mm) + "-" + yy;

  }

  function formatedate_full(date = new Date()) {

    var months_th = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
    // var months_th_mini = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];

    let d = new Date(date);
    let dd = d.getDate();
    let mm = d.getMonth();
    let yy = d.getFullYear() + 543;

    return dd + " " + months_th[mm] + " " + yy;

  }

  async function getDashboard() {

    // setLoadingDataDashboard(true);

    await HttpRequest("/api/getdashboard",
      {
        "date": search_date,
        "peak_id": search_time,
        "province_id": search_province
      }
    ).then((data) => {
      console.log("HttpRequest getdashboard  :", data);

      if (data.result === "OK") {
        // setLoadingDataDashboard(false);
        SET_DATA_ZONE(data.zones); // error?
        SET_DATA_OD(data.od);
        // SET_DATA_ZONE(updatedZones);
      } else {
        // setLoadingDataDashboard(false);
      }
    });

  }

  async function getPlace() {
    await HttpRequest("/api/getplace",
      {
        "search": "",
        "province_id": search_province,
        "index:": 0,
        "count": 0
      }
    ).then((data) => {
      console.log("HttpRequest getplace :", data);
      if (data.result === "OK") {
        let places = data.places;

        let industrialestate = [];
        let airport = [];
        let port = [];
        let bus = [];
        let train = [];

        for (let i = 0; i < places.length; i++) {
          const place = places[i];
          switch (place.place_type) {
            case "industrialestate":
              industrialestate.push(place);
              break;
            case "airport":
              airport.push(place);
              break;
            case "port":
              port.push(place);
              break;
            case "bus":
              bus.push(place);
              break;
            case "train":
              train.push(place);
              break;

            default:
              break;
          }
        }

        // console.log(train);

        SET_DATA_MAP_INDUSTRIAL(industrialestate);
        SET_DATA_MAP_AIRPORT(airport);
        SET_DATA_MAP_PORT(port);
        SET_DATA_MAP_BUS(bus);
        SET_DATA_MAP_TRAIN(train);

        SET_DATA_PLACE(places);

      } else {

      }
    });
  }

  function getDate(dateString) {
    let date = new Date(dateString);
    if (date.getDate() === 1) {
      return date.getMonth() + 1;
    } else {
      return ""
    }

  }

  // const onSelectRoute = React.useCallback((e) => {
  //   SET_ROUTE_SELECTED(e.target.feature.properties.data);
  // }, []);

  function toggleContentChart(name) {
    if (name === STATE_CONTENT_CHART) {
      SET_STATE_CONTENT_CHART(null);
    } else {
      SET_STATE_CONTENT_CHART(name);
    }
  }

  React.useEffect(() => {
    const controller = new AbortController();

    let current_date = formatedate();
    set_search_date(current_date);

    return () => {
      controller.abort();
    };
  }, []);

  React.useEffect(() => {
    const controller = new AbortController();

    if (globalState.enum) {

      let data_enum = { ...globalState.enum }

      SET_ENUM(data_enum);

      let new_state_option_map = {
        ...mapSettingDefault,
        center: [data_enum.default.center_lat, data_enum.default.canter_lon],
        raduis_m: data_enum.default.raduis_m
      }

      setMapSettingDefault(new_state_option_map);

      for (let i = 0; i < data_enum.filter_traffic.length; i++) {
        data_enum.filter_traffic[i].lists = [];
      }

      SET_FILTER_TRAFFIC_OPTION(data_enum.filter_traffic);

      SET_PEAKOPTION(data_enum.peak_dashboard_truck);
      console.log(data_enum.peak_dashboard_truck);
      SET_PROVINCES(data_enum.filter_province);
      SET_PERIODOPTION(data_enum.period_dashboard);

      if (data_enum.filter_province.length > 0) {
        set_search_province(data_enum.filter_province[0].id);
      }

      setLegendLabel(data_enum.condition_volume);

    }

    return () => {
      controller.abort();
    };
  }, [globalState]);

  React.useEffect(() => {
    const controller = new AbortController();
    SET_TRAFFIC_MAP([]);
    SET_POLYGON_MAP([]);
    SET_PIN_MAP([]);

    async function init() {
      setLoadingMap(true);
      await getDashboard();
      await getTraffic(); //// Show color line on road

      await getPlace();
    }

    if (ENUM) {
      init();
    }

    return () => {
      controller.abort();
    };
  }, [search_time, search_province, search_month]);


  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_OD) {
      //let data_bar_chart = [["date", "value", { 'type': 'string', 'role': 'tooltip', 'p': { 'html': true } }]];
      let data_bar_chart = [["date", "value", { 'type': 'string', 'role': 'tooltip', 'p': { 'html': true } }, { 'role': 'style' }]];

      for (let i = 0; i < DATA_OD.od_dates.length; i++) {
        const row = DATA_OD.od_dates[i];

        // new code
        const date = new Date(row.date);  // แปลงวันที่จากข้อมูล
        const dayOfWeek = date.getDay();  // ค่าที่แสดงวันในสัปดาห์ (0 = อาทิตย์, 6 = เสาร์)

        // ตรวจสอบว่าตรงกับวันเสาร์ (6) หรืออาทิตย์ (0) เพื่อกำหนดสีส้ม
        let barColor = (dayOfWeek === 0 || dayOfWeek === 6) ? 'orange' : 'white';

        // console.log(getDate(row.date));
        data_bar_chart.push(
          [
            // { v: getDate(row.date), f: row.date },
            // { v: (i+1), f: row.date },
            // { v: "", f: row.date },
            // getDate(row.date),
            row.x_label,
            parseInt(row.total_vehicle),
            row.y_label,
            barColor  // colors
          ]
        );
      }
      // console.log('This is data_bar_chart', data_bar_chart);
      SET_DATA_CHART_BAR(data_bar_chart);

      let data_pie_chart = [
        ["toppic", "value"],
        ["เดินทางภายในพื้นที่", parseInt(DATA_OD.total_internal_vehicle)],
        ["เดินทางเข้าออกพื้นที่่", parseInt(DATA_OD.total_cross_vehicle)],
        ["เดินทางผ่านพื้นที่", parseInt(DATA_OD.total_external_vehicle)]
      ];

      // console.log(data_pie_chart);
      SET_DATA_CHART_PIE(data_pie_chart);
    }

    return () => {
      controller.abort();
    };
  }, [DATA_OD]);

  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_TRAFFIC) {

      setLoadingMap(true);

      let filter_traffic = [...FILTER_TRAFFIC_OPTION];

      for (let i = 0; i < FILTER_TRAFFIC_OPTION.length; i++) {
        const key = FILTER_TRAFFIC_OPTION[i].id;

        if (DATA_TRAFFIC[key]) {
          filter_traffic[i].lists = DATA_TRAFFIC[key];
        }

        let count_max = 0;

        for (let j = 0; j < filter_traffic[i].lists.length; j++) {
          let value = filter_traffic[i].lists[j].value;
          if (value > count_max) {
            count_max = value;
          }
          filter_traffic[i].lists[j].type = key;
        }

        for (let j = 0; j < filter_traffic[i].lists.length; j++) {
          filter_traffic[i].lists[j].total = count_max;
          filter_traffic[i].lists[j].percent_value = ((filter_traffic[i].lists[j].value / count_max) * 100).toFixed(1);
        }
      }

      SET_FILTER_TRAFFIC_OPTION(filter_traffic);
      setLoadingMap(false);
    }

    return () => {
      controller.abort();
    };
  }, [DATA_TRAFFIC]);

  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_TRAFFIC) {
      if (DATA_MAP_LINK) {
        let polyline = [...DATA_TRAFFIC.links];

        for (let i = 0; i < polyline.length; i++) {
          polyline[i].color = "#999";
          let status = ENUM.condition_safety.filter((legend) => polyline[i].status === legend.status);
          let weight = legendLabel.filter((legend) => polyline[i].status_volume === legend.status)
          // legendLabel

          polyline[i].type = "traffic";

          if (status.length > 0) {
            polyline[i].color = status[0].color;
            polyline[i].color = 'magenta';  //  Color in line map
          }

          if (weight.length > 0) {
            polyline[i].size = (weight[0].size.replace("px", ""));
          }
        }

        SET_TRAFFIC_MAP(polyline);

      } else {
        setTimeout(() => {
          SET_TRAFFIC_MAP([]);
        }, 1000);
      }
    }
    return () => {
      controller.abort();
    };
  }, [DATA_MAP_LINK]);

  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_PLACE) {

      SET_PIN_MAP([]);
      SET_POLYGON_MAP([]);

      let pins = [];
      let polygons = [];

      if (DISPLAY_MAP_INDUSTRIAL && DATA_MAP_INDUSTRIAL) {
        // console.log("DISPLAY_MAP_INDUSTRIAL");
        let pins_industrial = [...DATA_MAP_INDUSTRIAL];

        for (let i = 0; i < pins_industrial.length; i++) {
          pins_industrial[i].pin_type = "industrialestate";
        }
        pins = [...pins, ...pins_industrial];
        polygons = [...polygons, ...pins_industrial];
      }

      if (DISPLAY_MAP_PORT && DATA_MAP_PORT) {
        // console.log("DISPLAY_MAP_PORT");
        let pins_port = [...DATA_MAP_PORT];
        for (let i = 0; i < pins_port.length; i++) {
          pins_port[i].pin_type = "port";
        }
        pins = [...pins, ...pins_port];
        polygons = [...polygons, ...pins_port];
      }

      if (DISPLAY_MAP_AIRPORT && DATA_MAP_AIRPORT) {
        // console.log("DISPLAY_MAP_AIRPORT");
        let pins_airport = [...DATA_MAP_AIRPORT];
        for (let i = 0; i < pins_airport.length; i++) {
          pins_airport[i].pin_type = "airport";
        }
        pins = [...pins, ...pins_airport];
        polygons = [...polygons, ...pins_airport];
      }

      if (DISPLAY_MAP_BUS && DATA_MAP_BUS) {
        // console.log("DISPLAY_MAP_BUS");
        let pins_bus = [...DATA_MAP_BUS];
        for (let i = 0; i < pins_bus.length; i++) {
          pins_bus[i].pin_type = "bus";
        }
        pins = [...pins, ...pins_bus];
        // console.log(pins_bus);
        // polygons = [...polygons, ...pins_bus];
      }

      if (DISPLAY_MAP_TRAIN && DATA_MAP_TRAIN) {
        // console.log("DISPLAY_MAP_BUS");
        let pins_train = [...DATA_MAP_TRAIN];
        for (let i = 0; i < pins_train.length; i++) {
          pins_train[i].pin_type = "train";
        }
        pins = [...pins, ...pins_train];
        // console.log(pins_train);
        polygons = [...polygons, ...pins_train];
      }

      SET_PIN_MAP(pins);
      SET_POLYGON_MAP(polygons);
    }

    return () => {
      controller.abort();
    };
  }, [
    DISPLAY_MAP_INDUSTRIAL, DATA_MAP_INDUSTRIAL,
    DISPLAY_MAP_PORT, DATA_MAP_PORT,
    DISPLAY_MAP_AIRPORT, DATA_MAP_AIRPORT,
    DISPLAY_MAP_BUS, DATA_MAP_BUS,
    DISPLAY_MAP_TRAIN, DATA_MAP_TRAIN,
  ]);

  //  Speed morter
  return (
    <div className="content-tab">

      <div className="content-map">
        <div className="content-map-header">
          <div className="container-input">
            <label>พื้นที่</label>
            <select
              value={search_province}
              onChange={(event) => set_search_province(event.target.value)}
            >
              {PROVINCES.map((option, index) => {
                return (<option key={index} value={option.id}>{option.title}</option>);
              })}
            </select>
          </div>

          <div className="container-input">
            <label>เวลา</label>
            <select
              value={search_time}
              onChange={(event) => set_search_time(event.target.value)}
            >
              {PEAKOPTION.map((option, index) => {
                return (<option key={index} value={option.id}>{option.title}</option>);
              })}
            </select>
          </div>


          <span className="container-input">
            <label>ช่วงเวลา</label>
            <select
              value={search_month}
              onChange={(event) => set_search_month(event.target.value)}>
              {PERIODOPTION.map((option, index) => {
                return (<option key={index} value={option.id}>{option.title}</option>);
              })}
            </select>
          </span>


          <span className="container-input">
            <label style={{ width: "200px" }}>ประเภทของวัน</label>
            <select
              value={'?'}
              onChange={(event) => set_search_month(event.target.value)}>
              <option>วันทำงาน</option>
              <option>วันหยุด</option>
              {/*PERIODOPTION.map((option, index) => {
                return (<option key={index} value={option.id}>{option.title}</option>);
              })*/}
            </select>
          </span>

          <div className="web" style={{ flexGrow: 1 }}></div>

          {/* {
            RANGE_TIME_DATA ? (
              <div className="date">
                <span>{RANGE_TIME_DATA.last_date}</span>
                <span style={{ fontSize: 14 }}>ข้อมูลเมื่อเวลา {RANGE_TIME_DATA.last_time}</span>
              </div>
            ) : (
              <Spinner></Spinner>
            )
          } */}
        </div>

        <div className="content-map-content">

          {
            loadingMap ? (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                <Spinner></Spinner>
                <div style={{ color: "#000" }}>กำลังโหลด</div>
              </div>
            ) : (
              <OpenStreetMap
                traffic={TRAFFIC_MAP}
                pinmap={PIN_MAP}
                polygon={POLYGON_MAP}
                center={mapSettingDefault.center}
                zoom={mapSettingDefault.zoom}

              // onSelectRoute={() => onSelectRoute}
              />
            )
          }

          <div className="overlay-map-detail">
            <div className="checkbox-display-container tab23">
              <div className="head-label">โครงสร้างพื้นฐานคมนาคม</div>

              <label className="custom-checkmark checkbox">
                <input type="checkbox" checked={DISPLAY_MAP_PORT} onChange={(event) => SET_DISPLAY_MAP_PORT(event.target.checked)} />
                <span className="checkmark"></span>
                <img className="icon" src={iconPinPort} />
                <div className="label">ท่าเรือ</div>
              </label>

              <label className="custom-checkmark checkbox">
                <input type="checkbox" checked={DISPLAY_MAP_INDUSTRIAL} onChange={(event) => SET_DISPLAY_MAP_INDUSTRIAL(event.target.checked)} />
                <span className="checkmark"></span>
                <img className="icon" src={iconPinIndustrial} />
                <div className="label">นิคมอุตสาหกรรม</div>
              </label>

              <label className="custom-checkmark checkbox">
                <input type="checkbox" checked={DISPLAY_MAP_AIRPORT} onChange={(event) => SET_DISPLAY_MAP_AIRPORT(event.target.checked)} />
                <span className="checkmark"></span>
                <img className="icon" src={iconPinAirport} />
                <div className="label">ท่าอากาศยาน</div>
              </label>

              <label className="custom-checkmark checkbox">
                <input type="checkbox" checked={DISPLAY_MAP_BUS} onChange={(event) => SET_DISPLAY_MAP_BUS(event.target.checked)} />
                <span className="checkmark"></span>
                <img className="icon" src={iconPinBus} />
                <div className="label">บขส.</div>
              </label>

              <label className="custom-checkmark checkbox">
                <input type="checkbox" checked={DISPLAY_MAP_TRAIN} onChange={(event) => SET_DISPLAY_MAP_TRAIN(event.target.checked)} />
                <span className="checkmark"></span>
                <img className="icon" src={iconPinTrain} />
                <div className="label">รถไฟ</div>
              </label>
            </div>


            <div className="checkbox-display-container tab23 legend-map">
              <div className="head-label">จำนวนรถบรรทุก</div>
              {
                legendLabel.map((list, index) => (
                  // <div key={index} className="legend" style={{ backgroundColor: list.color }}>{list.title}</div>
                  <div className="legend" key={index}>
                    <div className="color" style={{
                      background: list.color ? list.color : "magenta",
                      minHeight: list.size ? "unset" : 20,
                      height: list.size ? parseInt(list.size) : 20
                    }}></div>
                    <div className="text">{list.title}</div>
                  </div>
                ))
              }
            </div>
          </div>

        </div>
      </div>

      <div className={"content-detail tab1 " + (STATE_CONTENT_CHART === "detail" ? "active" : "")}>

        <div className="chart" style={{ background: "#6D8494" }}>
          <div className="chart-title mobile" onClick={() => toggleContentChart("detail")}>ข้อมูลภาพรวม</div>

          <div className="title-bottom-chart">ปริมาณรถบรรทุกตั้งแต่ 10 ล้อขึ้นไป <br />รวมเข้าออกและผ่านพื้นที่ทั้งหมด (คัน-เที่ยว/วัน)</div>
          <div className="bar-chart">
            {
              DATA_CHART_BAR ? (
                <Fragment>
                  <div className="web" style={{ width: "100%" }}>
                    {/** กราฟแท่ง เวป */}
                    <Chart
                      chartType="ColumnChart"
                      width="100%"
                      height="100%"
                      data={DATA_CHART_BAR} // กราฟแท่ง
                      options={
                        {
                          // title: "ปริมาณรถบรรทุกตั้งแต่ 10 ล้อขึ้นไป เข้าและออกพื้นที่ (คัน-เที่ยว/วัน)",
                          titleTextStyle: {
                            fontSize: 20,
                            color: '#FFF',
                            fontName: 'mainFont'
                          },

                          //colors: ['white'], // สีกราฟแท่ง 
                          backgroundColor: 'transparent',
                          legend: { position: 'none' },
                          hAxis: {
                            title: "วันที่",
                            titleTextStyle: {
                              color: '#FFF'
                            },
                            minValue: 0,
                            textStyle: { // แกน x date color = red
                              fontSize: 10,
                              color: "#ff0067",
                              fontWeight: "bolder"
                            }
                          },
                          vAxis: { // แกน y 0 - 45000 color = yellow
                            textStyle: {
                              color: "#ffce00",
                              fontWeight: "bolder"
                            }
                          },
                          chartArea: { left: 80, top: 20, right: 50, bottom: 40 },
                          tooltip: { isHtml: true }
                        }
                      }
                    />
                  </div>

                  {/** กราฟแท่ง มือถือ */}
                  <div className="mobile">
                    <Chart
                      chartType="ColumnChart"
                      width="100%"
                      height="100%"
                      data={DATA_CHART_BAR}
                      options={
                        {
                          // title: "ปริมาณรถบรรทุกตั้งแต่ 10 ล้อขึ้นไป เข้าและออกพื้นที่ (คัน-เที่ยว/วัน)",
                          titleTextStyle: {
                            fontSize: 16,
                            color: '#FFF',
                            fontName: 'mainFont'
                          },
                          //colors: ['white'], // สีกราฟแท่ง 
                          backgroundColor: 'transparent',
                          legend: { position: 'none' },
                          hAxis: {
                            title: "วันที่",
                            titleTextStyle: {
                              color: '#FFF'
                            },
                            minValue: 0,
                            textStyle: {
                              fontSize: 10,
                              color: "#ffce00",
                              fontWeight: "bolder"
                            }
                          },
                          vAxis: {
                            textStyle: {
                              color: "#ffce00",
                              fontWeight: "bolder"
                            }
                          },
                          chartArea: { left: 40, top: 40, right: 20, bottom: 40 },
                        }
                      }
                    />
                  </div>

                </Fragment>

              ) : (null)
            }
          </div>

          <div style={{
            width: "100%",
            height: 1,
            background: "#FFF"
          }}></div>

          <div className="title-bottom-chart">สัดส่วนการเดินทางของรถบรรทุกตั้งแต่ 10 ล้อ ขึ้นไป</div>

          <div className="pie-chart">
            {/* <div style={{
              color: "#FFF",
              fontWeight: "bolder",
              fontSize: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              minWidth: 200,
              textShadow: "2px 2px #000"
            }}>
              <span>จำนวนการเดินทาง</span>
              <span>ณ เวลาล่าสุด</span>
              <span>(คันเที่ยว/วัน)</span>
              <span style={{ fontSize: 16, textShadow: "none", marginTop: 10 }}>รถบรรทุกตั้งแต่ 10 ล้อขึ้นไป</span>
            </div> */}
            {
              DATA_CHART_PIE ? (
                <Fragment >
                  <div className="web" style={{ width: "100%" }}>
                    <Chart
                      chartType="PieChart"
                      width="100%"
                      height="100%"
                      data={DATA_CHART_PIE}
                      options={
                        {
                          title: "",
                          titleTextStyle: {
                            fontSize: 20,
                            color: '#FFF'
                          },
                          is3D: true,
                          // colors: ['#C00000', '#0000FF', '#FFaa00'], 
                          colors: ['#C00000', '#0000FF', 'cyan'],
                          backgroundColor: 'transparent',
                          legend: {
                            textStyle: {
                              color: '#FFF',
                              fontSize: 16,
                              fontName: 'mainFont',
                              fontWeight: "bolder"
                            },
                            position: 'right',
                            alignment: 'center'
                          },
                          hAxis: {
                            minValue: 0,
                            textStyle: {
                              fontSize: 10,
                              color: "#ffce00"
                            }
                          },
                          vAxis: {
                            textStyle: {
                              color: "#ffce00"
                            }
                          },
                          chartArea: { left: 0, top: 0, right: 20, bottom: 0 },
                        }
                      }
                    />
                  </div>
                  <div className="mobile" style={{ width: "100%", minHeight: 300 }}>
                    <Chart
                      chartType="PieChart"
                      width="100%"
                      height="100%"
                      data={DATA_CHART_PIE}
                      options={
                        {
                          title: "",
                          titleTextStyle: {
                            fontSize: 20,
                            color: '#FFF'
                          },
                          is3D: true,
                          // colors: ['#C00000', '#0000FF', '#FFaa00'],
                          colors: ['#C00000', '#0000FF', 'cyan'],
                          backgroundColor: 'transparent',
                          legend: {
                            textStyle: {
                              color: '#FFF',
                              fontName: 'mainFont',
                              fontWeight: "bolder"
                            },
                            position: 'bottom',
                            alignment: 'center'
                          },
                          hAxis: {
                            minValue: 0,
                            textStyle: {
                              fontSize: 10,
                              color: "#ffce00"
                            }
                          },
                          vAxis: {
                            textStyle: {
                              color: "#ffce00"
                            }
                          },
                          chartArea: { left: 0, top: 0, right: 0, bottom: 50 },
                        }
                      }
                    />
                  </div>
                </Fragment>

              ) : (null)
            }
          </div>
        </div>

        <span className="summary" >
          <div className="box">
            <div className="value">{DATA_ZONE ? DATA_ZONE.total_ban_vehicle : null}</div>
            <div className="unit">
              จำนวนรถบรรทุก<br />ที่วิ่งบนเส้นทางห้ามรถบรรทุกเข้าและจอด<br />({DATA_ZONE ? DATA_ZONE.total_ban_vehicle_unit : null})
            </div>
          </div>
          <div className="box">
            <div className="value">{DATA_ZONE ? DATA_ZONE.total_speed_vehicle : null}</div>
            <div className="unit">
              จำนวนรถบรรทุก<br />ที่ใช้ความเร็วเกินกว่าที่กฏหมายกำหนด<br />({DATA_ZONE ? DATA_ZONE.total_speed_vehicle_unit : null})
            </div>
          </div>
        </span>

      </div>

    </div>
  );
}

export default App;
