import React, { createContext, useContext, useReducer, useMemo } from "react";

const GlobalContext = createContext();
GlobalContext.displayName = "GlobalContext";

function reducer(state, action) {
  // console.log(action.type + " ---> " + action.value);
  switch (action.type) {
    case "AUTHENTICATION": {
      return { ...state, auth: action.value };
    }
    case "CONFIG_APP": {
      return { ...state, config_app: action.value };
    }
    case "LOADING": {
      if (state !== action.value) {
        return { ...state, showLoading: action.value };
      }
      return { ...state };
    }
    case "CONFIG_SERVER": {
      return { ...state, config_server: action.value };
    }
    case "ENUM": {
      return { ...state, enum: action.value };
    }
    case "ROLE": {
      return { ...state, role: action.value };
    }
    case "HINT_CONTENT": {
      return { ...state, hint_content: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function GlobalContextProvider({ children }) {
  const initialState = {
    auth: null,
    config_app: null,
    showLoading: false,
    config_server: null,
    enum: null,
    hint_content: false
  };

  const [globalState, setGlobalState] = useReducer(reducer, initialState);

  const value = useMemo(
    () => [globalState, setGlobalState],
    [globalState, setGlobalState]
  );

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}

function useGlobalContext() {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error(
      "useGlobalContext should be used inside the GlobalContextProvider."
    );
  }

  return context;
}

const setAuthentication = (setGlobalState, value) =>
  setGlobalState({ type: "AUTHENTICATION", value });
const setConfigApp = (setGlobalState, value) =>
  setGlobalState({ type: "CONFIG_APP", value });
const setShowLoading = (setGlobalState, value) =>
  setGlobalState({ type: "LOADING", value });
const setConfigServer = (setGlobalState, value) =>
  setGlobalState({ type: "CONFIG_SERVER", value });
const setEnum = (setGlobalState, value) =>
  setGlobalState({ type: "ENUM", value });
const setRole = (setGlobalState, value) =>
  setGlobalState({ type: "ROLE", value });
const setHintContent = (setGlobalState, value) =>
  setGlobalState({ type: "HINT_CONTENT", value });


export {
  GlobalContextProvider,
  useGlobalContext,
  setAuthentication,
  setConfigApp,
  setShowLoading,
  setConfigServer,
  setEnum,
  setRole,
  setHintContent
};
