import "./styles.css";
import React from "react";

import { HttpRequest, HttpRequestForCSV } from "../../service/HttpRequest";

import Spinner from "../../component/spinner";
import OpenStreetMap from "../../component/insane-openstreet-map";

import InsaneCollapseContent from "../../component/insane-collapse-content";
import InsaneInputSearch from "../../component/insane-input-search";
import InsaneDatePicker from "../../component/insane-date-picker";

// import InsaneRatioBar from "../../component/insane-ratio-bar";

// new import form trafic page
import iconPinPort from "../../assets/icons/transport - port.png";
import iconPinAirport from "../../assets/icons/transport - airport.png";
import iconPinBus from "../../assets/icons/transport - bus.png";
import iconPinIndustrial from "../../assets/icons/transport - industrial.png";
import iconPinTrain from "../../assets/icons/transport - train.png";

import WKX from "wkx";

import { useGlobalContext } from "../../service/GlobalContext";

function App() {

  const [globalState, setGlobalState] = useGlobalContext();

  //// Table Parametor
  const [loading, setLoading] = React.useState(false);
  const [loadingMap, setLoadingMap] = React.useState(false);

  const [search_date, set_search_date] = React.useState("");
  const [search_time, set_search_time] = React.useState("ALLDAY");

  const [search_province, set_search_province] = React.useState("");
  const [search_vehicle_type, set_search_vehicle_type] = React.useState("truck");

  const [search_location_start, set_search_location_start] = React.useState("");
  const [search_location_stop, set_search_location_stop] = React.useState("");

  const [search_od_mode, set_search_od_mode] = React.useState(0);


  const [fitBounds_MAP_ref, set_fitBounds_MAP_ref] = React.useState(null);

  // const [search_taz, set_search_taz] = React.useState(false);

  const [legendLabel, setLegendLabel] = React.useState([]);
  const [legendLabelOdMode, setLegendLabelOdMode] = React.useState([]);

  const [PIN_MAP, SET_PIN_MAP] = React.useState([]);
  const [POLYGON_MAP, SET_POLYGON_MAP] = React.useState([]);
  const [POLYLINE_MAP, SET_POLYLINE_MAP] = React.useState([]);
  const [CIRCLE_MAP, SET_CIRCLE_MAP] = React.useState([]);

  const [ENUM, SET_ENUM] = React.useState(null);
  const [PEAKOPTION, SET_PEAKOPTION] = React.useState([]);
  const [PROVINCES, SET_PROVINCES] = React.useState([]);
  const [VEHICLE_TYPE, SET_VEHICLE_TYPE] = React.useState([]);

  const [DATATABLE, SET_DATATABLE] = React.useState([]);

  const [state_filter, set_state_filter] = React.useState(false);
  const [state_detail, set_state_detail] = React.useState(false);

  const [mapSettingDefault, setMapSettingDefault] = React.useState({
    center: [13.762201991756932, 100.50106010074637],
    zoom: 10,
    raduis_m: 1000
  });

  const [DATA_PLACE, SET_DATA_PLACE] = React.useState(null);
  const [DATA_PLACE_CIRCLE, SET_DATA_PLACE_CIRCLE] = React.useState(null);
  const [DATA_OD_LINK, SET_DATA_OD_LINK] = React.useState(null);

  const [DISPLAY_OD_LINK, SET_DISPLAY_OD_LINK] = React.useState(true);

  const [DISPLAY_MAP_INDUSTRIAL, SET_DISPLAY_MAP_INDUSTRIAL] = React.useState(false);
  const [DISPLAY_MAP_PORT, set_DISPLAY_MAP_PORT] = React.useState(false);
  const [DISPLAY_MAP_AIRPORT, SET_DISPLAY_MAP_AIRPORT] = React.useState(false);
  const [DISPLAY_MAP_BUS, SET_DISPLAY_MAP_BUS] = React.useState(false);
  const [DISPLAY_MAP_TRAIN, SET_DISPLAY_MAP_TRAIN] = React.useState(false);

  const [DATA_MAP_INDUSTRIAL, SET_DATA_MAP_INDUSTRIAL] = React.useState([]);
  const [DATA_MAP_PORT, SET_DATA_MAP_PORT] = React.useState([]);
  const [DATA_MAP_BUS, SET_DATA_MAP_BUS] = React.useState([]);
  const [DATA_MAP_AIRPORT, SET_DATA_MAP_AIRPORT] = React.useState([]);
  const [DATA_MAP_TRAIN, SET_DATA_MAP_TRAIN] = React.useState(false);

  const [SELECTED_OD, SET_SELECTED_OD] = React.useState(null);

  function downloadCSV(csvContent, fileName) {
    // console.log(csvContent);
    let encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  async function getPlaceLocation() {
    await HttpRequest("/api/getplace",
      {
        "search": "",
        "index:": 0,
        "count": 0,
        "province_id": search_province
      }
    ).then((data) => {
      console.log("HttpRequest getplace (getPlaceLocation) :", data);
      if (data.result === "OK") {
        let places = data.places;

        let industrialestate = [];
        let airport = [];
        let port = [];
        let bus = [];
        let train = [];

        for (let i = 0; i < places.length; i++) {
          const place = places[i];
          switch (place.place_type) {
            case "industrialestate":
              industrialestate.push(place);
              break;
            case "airport":
              airport.push(place);
              break;
            case "port":
              port.push(place);
              break;
            case "bus":
              bus.push(place);
              break;
            case "train":
              train.push(place);
              break;

            default:
              break;
          }
        }

        SET_DATA_MAP_INDUSTRIAL(industrialestate);
        SET_DATA_MAP_AIRPORT(airport);
        SET_DATA_MAP_PORT(port);
        SET_DATA_MAP_BUS(bus);
        SET_DATA_MAP_TRAIN(train);

        SET_DATA_PLACE(places);

      } else {

      }
    });
  }

  async function getPlace() {

    await HttpRequest("/api/getplace",
      {
        "search": "",
        "taz": true,
        "index:": 0,
        "count": 0,
        "province_id": search_province,
        "wkb": true
      }
    ).then((data) => {
      console.log("HttpRequest getplace :", data);
      if (data.result === "OK") {
        let places = data.places;

        for (let i = 0; i < places.length; i++) {
          places[i].pin_type = "places";
          let decode = Buffer.from(places[i].wkb, 'base64');
          places[i].geojson = WKX.Geometry.parseTwkb(decode).toGeoJSON();
        }

        let polygons = places.filter((p) => p.geojson.type !== "Point")

        SET_POLYGON_MAP(polygons);
        SET_CIRCLE_MAP(places);
        SET_DATA_PLACE_CIRCLE(places);

      } else {

      }
    });
  }

  async function getOd() {
    console.log(search_time);

    await HttpRequest("/api/getOd",
      {
        "o_place_id": search_location_start,
        "d_place_id": search_location_stop,
        "date": search_date,
        "peak_id": search_time,
        "province_id": search_province,
        "taz": true,
        "index": 0,
        "count": 0,
        "vehicle_category": search_vehicle_type,
        "od_mode": search_od_mode
      }
    ).then((data) => {
      console.log("HttpRequest getOd :", data);
      if (data.result === "OK") {
        let places = data.places;
        let condition = data.condition_od_volume;

        let totalods = data.totalods;

        for (let i = 0; i < places.length; i++) {

          places[i].color = "#999";
          let status = ENUM.condition_od_mode.filter((legend) => places[i].mode_status === legend.status);
          if (status.length > 0) {
            places[i].color = status[0].color;
          }

          places[i].size = 5;
          let volume = condition.filter((legend) => places[i].volume_status === legend.status);
          if (volume.length > 0) {
            places[i].size = parseInt(volume[0].size);
          }

          places[i].geojson = {
            coordinates: [
              [places[i].o_lat, places[i].o_lon],
              [places[i].d_lat, places[i].d_lon]
            ]
          }

          places[i].type_polyline = "od";
        }


        let condition_mode = [...ENUM.condition_od_mode];

        for (let i = 0; i < condition_mode.length; i++) {
          let match_mode_status = totalods.filter((status) => status.mode_status === ENUM.condition_od_mode[i].status);
          if (match_mode_status.length > 0) {
            ENUM.condition_od_mode[i].count = match_mode_status[0].total;
          } else {
            ENUM.condition_od_mode[i].count = ""
          }
        }

        setLegendLabelOdMode(condition_mode);

        setLegendLabel(condition);

        SET_DATATABLE(places);
        SET_DATA_OD_LINK(places);

      } else {

      }
    });

  }

  async function downloadOD() {
    // setLoading(true);
    await HttpRequestForCSV("/api/downloadod ",
      {
        "o_place_id": search_location_start,
        "d_place_id": search_location_stop,
        "date": search_date,
        "peak_id": search_time,
        "taz": true,
        "index": 0,
        "count": 0,
        "vehicle_category": search_vehicle_type
      }
    ).then((data) => {
      // console.log("HttpRequest downloadod :", data);
      let fileName = ("OD_" + search_date + "_" + search_time);
      downloadCSV(data, fileName);
    });
  }

  function getOdLink(o_place_id, d_place_id) {
    setLoading(true);
    SET_POLYLINE_MAP([]);

    HttpRequest("/api/getodlink",
      {
        "o_place_id": o_place_id,
        "d_place_id": d_place_id,
        "date": search_date,
        "peak_id": search_time,
        "taz": true,
        "index:": null,
        "count": null,
        "vehicle_category": search_vehicle_type
      }
    ).then((data) => {
      console.log("HttpRequest getOdLink :", data);
      if (data.result === "OK") {

        let polyline = data.odlink.links;

        let arr_bounds = [];

        try {
          for (let i = 0; i < polyline.length; i++) {

            polyline[i].color = "#999";
            let status = ENUM.condition_traffic.filter((legend) => polyline[i].status === legend.status);

            if (status.length > 0) {
              polyline[i].color = status[0].color;
            }

            for (let j = 0; j < polyline[i].geojson.coordinates.length; j++) {
              polyline[i].geojson.coordinates[j].reverse();
            }

            polyline[i].type_polyline = "traffic";

            if (polyline[i].geojson.coordinates.length > 0) {
              arr_bounds = [...arr_bounds, ...polyline[i].geojson.coordinates];
            }
          }

          // console.log(arr_bounds);

          SET_POLYLINE_MAP(polyline);

          if (polyline.length > 0) {
            set_fitBounds_MAP_ref(arr_bounds);
          }
        } catch (error) {
          console.log(error);
        }

        setLoading(false);
      } else {

        setLoading(false);
      }
    });
  }

  function onClearOD() {
    // SET_POLYLINE_MAP(LINK_OD);

    set_search_location_start("");
    set_search_location_stop("");

    set_fitBounds_MAP_ref([]);
    SET_SELECTED_OD(null);

    getOd();
  }

  function formatedate(date = new Date()) {
    let d = new Date(date);
    let dd = d.getDate();
    let mm = d.getMonth() + 1;
    let yy = d.getFullYear();

    function zerobefore(n) { return (n > 9) ? (n) : ("0" + n) }

    return yy + "-" + zerobefore(mm) + "-" + zerobefore(dd)
  }

  function getCurrentHour() {
    let d = new Date();
    return d.getHours();
  }

  function getUnitTimeSelected() {
    let result = PEAKOPTION.filter((peak) => peak.id === search_time);
    if (result.length > 0) {
      return result[0].unit
    } else {
      return ""
    }
  }

  function getDisplayFilter(main_key, display_key, find_key, find_value) {
    // console.log(ENUM);
    if (ENUM) {
      let arr_filter = ENUM[main_key];
      if (arr_filter) {
        let result = arr_filter.filter((e) => e[find_key] === find_value);
        if (result.length > 0) {
          return result[0][display_key];
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  }

  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_PLACE) {
      let pins = [];

      if (DISPLAY_MAP_INDUSTRIAL && DATA_MAP_INDUSTRIAL) {
        // console.log("DISPLAY_MAP_INDUSTRIAL");
        let pins_industrial = [...DATA_MAP_INDUSTRIAL];

        for (let i = 0; i < pins_industrial.length; i++) {
          pins_industrial[i].pin_type = "industrialestate";
        }

        pins = [...pins, ...pins_industrial];
      }

      if (DISPLAY_MAP_PORT && DATA_MAP_PORT) {
        // console.log("DISPLAY_MAP_PORT");
        let pins_port = [...DATA_MAP_PORT];
        for (let i = 0; i < pins_port.length; i++) {
          pins_port[i].pin_type = "port";
        }

        pins = [...pins, ...pins_port];
      }

      if (DISPLAY_MAP_AIRPORT && DATA_MAP_AIRPORT) {
        // console.log("DISPLAY_MAP_AIRPORT");
        let pins_airport = [...DATA_MAP_AIRPORT];
        for (let i = 0; i < pins_airport.length; i++) {
          pins_airport[i].pin_type = "airport";
        }

        pins = [...pins, ...pins_airport];
      }

      if (DISPLAY_MAP_BUS && DATA_MAP_BUS) {
        // console.log("DISPLAY_MAP_BUS");
        let pins_bus = [...DATA_MAP_BUS];
        for (let i = 0; i < pins_bus.length; i++) {
          pins_bus[i].pin_type = "bus";
        }

        pins = [...pins, ...pins_bus];
      }


      if (DISPLAY_MAP_TRAIN && DATA_MAP_TRAIN) {
        // console.log("DISPLAY_MAP_TRAIN");
        let pins_train = [...DATA_MAP_TRAIN];
        for (let i = 0; i < pins_train.length; i++) {
          pins_train[i].pin_type = "train";
        }
        pins = [...pins, ...pins_train];
      }

      SET_PIN_MAP(pins);
    }

    return () => {
      controller.abort();
    };
  }, [
    DISPLAY_MAP_INDUSTRIAL, DATA_MAP_INDUSTRIAL,
    DISPLAY_MAP_PORT, DATA_MAP_PORT,
    DISPLAY_MAP_AIRPORT, DATA_MAP_AIRPORT,
    DISPLAY_MAP_BUS, DATA_MAP_BUS,
    DISPLAY_MAP_TRAIN, DATA_MAP_TRAIN
  ]);


  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_OD_LINK) {
      if (DISPLAY_OD_LINK) {

        SET_POLYLINE_MAP([]);

        SET_CIRCLE_MAP([]);

        let places = [...DATA_OD_LINK];
        let places_polyline = [];

        for (let j = 0; j < legendLabelOdMode.length; j++) {
          if (legendLabelOdMode[j].checked) {

            let match_place_by_mode_status = places.filter((place) => place.mode_status === legendLabelOdMode[j].status);
            let match_place_by_available_od = match_place_by_mode_status.filter((place) => ((place.o_lat && place.o_lon) && (place.d_lat && place.d_lon)));

            places_polyline = [...places_polyline, ...match_place_by_available_od]
          }
        }

        SET_POLYLINE_MAP(places_polyline);

        setTimeout(() => {
          SET_CIRCLE_MAP(DATA_PLACE_CIRCLE);
        }, 500);

      } else {
        SET_POLYLINE_MAP([]);
      }
    }

    return () => {
      controller.abort();
    };
  }, [DATA_OD_LINK, DISPLAY_OD_LINK, legendLabelOdMode]);

  React.useEffect(() => {
    const controller = new AbortController();

    if (globalState.enum) {

      let current_date = formatedate();
      set_search_date(current_date);

      let data_enum = { ...globalState.enum };
      SET_ENUM(data_enum);

      let new_state_option_map = {
        ...mapSettingDefault,
        center: [data_enum.default.center_lat, data_enum.default.canter_lon],
        raduis_m: data_enum.default.raduis_m
      }

      // setLegendLabel([...data_enum.condition_od_speed]);
      setLegendLabel([...data_enum.condition_od_volume]);

      for (let i = 0; i < data_enum.condition_od_mode.length; i++) {
        data_enum.condition_od_mode[i].checked = true;
      }

      setLegendLabelOdMode([...data_enum.condition_od_mode]);

      setMapSettingDefault(new_state_option_map);
      SET_PEAKOPTION(data_enum.peak_logistics);
      SET_PROVINCES(data_enum.filter_province);
      SET_VEHICLE_TYPE(data_enum.vehicle_category);

      if (data_enum.filter_province.length > 0) {
        set_search_province(data_enum.filter_province[0].id);
      }

    }

    return () => {
      controller.abort();
    };
  }, [globalState.enum]);


  React.useEffect(() => {
    const controller = new AbortController();

    SET_PIN_MAP([]);

    async function get() {
      await getPlaceLocation();
    }

    if (ENUM) {
      get();
    }

    return () => {
      controller.abort();
    };
  }, [ENUM]);

  React.useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    // SET_PIN_MAP([]);
    SET_POLYGON_MAP([]);
    SET_POLYLINE_MAP([]);
    SET_CIRCLE_MAP([]);

    async function get() {
      setLoadingMap(true);
      await getPlace();
      await getOd();
      // await getPlaceLocation();
      setLoadingMap(false);
    }

    if (ENUM && search_date && (search_date !== "")) {
      get();
    }


    setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      controller.abort();
    };
  }, [search_date, search_time, search_vehicle_type, search_province]);

  return (
    <>
      {loading ? (
        <div
          className="main-content"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Spinner></Spinner>
          <div style={{ color: "#000" }}>กำลังโหลด</div>
        </div>
      ) : (
        <div className="main-content">
          <div className="page-content">

            <InsaneCollapseContent title="FILTER" type="filter" color="#005baf" style={{ zIndex: 20 }} state={state_filter} stateChange={set_state_filter}>
              <div className="filter-content">

                <input
                  type="date"
                  className="form-control page-input input-date-mobile"
                  value={search_date}
                  onChange={(event) =>
                    set_search_date(event.target.value)
                  }
                />

                <InsaneDatePicker
                  type="date"
                  className="form-control page-input input-date-web"
                  value={search_date}
                  change={set_search_date}
                />

                <select
                  className="form-control page-input"
                  value={search_time}
                  onChange={(event) => {
                    console.log(event.target.value);
                    set_search_time(event.target.value);
                  }
                  }
                >
                  {PEAKOPTION.map((option, index) => {
                    return (<option key={index} value={option.id}>{option.title}</option>);
                  })}
                </select>

                <select
                  className="form-control page-input"
                  value={search_province}
                  onChange={(event) =>
                    set_search_province(event.target.value)
                  }
                >
                  {PROVINCES.map((option, index) => {
                    return (<option key={index} value={option.id}>{option.title}</option>);
                  })}
                </select>

                <select
                  className="form-control page-input"
                  value={search_vehicle_type}
                  onChange={(event) =>
                    set_search_vehicle_type(event.target.value)
                  }
                >
                  {VEHICLE_TYPE.map((option, index) => {
                    return (<option key={index} value={option.id}>{option.title}</option>);
                  })}
                </select>

                <div className="checkbox-display-container">
                  <div className="head-label">การแสดงผล</div>

                  {/* <label className="custom-checkmark checkbox">
                    <input type="checkbox" checked={search_taz} onChange={(event) => set_search_taz(event.target.checked)} />
                    <span className="checkmark"></span>
                    <div className="label">TAZ</div>
                  </label> */}

                  <label className="custom-checkmark checkbox">
                    <input type="checkbox" checked={DISPLAY_OD_LINK} onChange={(event) => SET_DISPLAY_OD_LINK(event.target.checked)} />
                    <span className="checkmark"></span>
                    <div className="label">จุดต้นทาง-จุดปลายทาง</div>
                  </label>

                  <button style={{ background: "#005baf", color: "#FFF" }} onClick={() => onClearOD()}>Clear OD</button>
                </div>


                <div className="checkbox-display-container">
                  <div className="head-label">โครงสร้างพื้นฐานคมนาคม</div>

                  <label className="custom-checkmark checkbox">
                    <input type="checkbox" checked={DISPLAY_MAP_PORT} onChange={(event) => set_DISPLAY_MAP_PORT(event.target.checked)} />
                    <span className="checkmark"></span>
                    <img className="icon" src={iconPinPort} />
                    <div className="label">ท่าเรือ</div>
                  </label>

                  <label className="custom-checkmark checkbox">
                    <input type="checkbox" checked={DISPLAY_MAP_INDUSTRIAL} onChange={(event) => SET_DISPLAY_MAP_INDUSTRIAL(event.target.checked)} />
                    <span className="checkmark"></span>
                    <img className="icon" src={iconPinIndustrial} />
                    <div className="label">นิคมอุตสาหกรรม</div>
                  </label>

                  <label className="custom-checkmark checkbox">
                    <input type="checkbox" checked={DISPLAY_MAP_AIRPORT} onChange={(event) => SET_DISPLAY_MAP_AIRPORT(event.target.checked)} />
                    <span className="checkmark"></span>
                    <img className="icon" src={iconPinAirport} />
                    <div className="label">ท่าอากาศยาน</div>
                  </label>

                  <label className="custom-checkmark checkbox">
                    <input type="checkbox" checked={DISPLAY_MAP_BUS} onChange={(event) => SET_DISPLAY_MAP_BUS(event.target.checked)} />
                    <span className="checkmark"></span>
                    <img className="icon" src={iconPinBus} />
                    <div className="label">บขส.</div>
                  </label>

                  <label className="custom-checkmark checkbox">
                    <input type="checkbox" checked={DISPLAY_MAP_TRAIN} onChange={(event) => SET_DISPLAY_MAP_TRAIN(event.target.checked)} />
                    <span className="checkmark"></span>
                    <img className="icon" src={iconPinTrain} />
                    <div className="label">รถไฟ</div>
                  </label>
                </div>



                <div style={{ flexGrow: 1 }}></div>
                {
                  (legendLabelOdMode.length > 0) ? (
                    <div className="checkbox-display-container" style={{ marginBottom: 10 }}>
                      {/* <div className="head-label">ความเร็วเฉลี่ย</div> */}
                      <div className="head-label">ทิศทางการเดินทาง</div>
                      {
                        legendLabelOdMode.map((legend, index) => {
                          return (
                            <label className="custom-checkmark checkbox" key={index}>
                              <input type="checkbox" checked={legend.checked}

                                onChange={(event) => {
                                  let new_state = [...legendLabelOdMode];
                                  new_state[index].checked = event.target.checked;
                                  setLegendLabelOdMode(new_state);
                                }} />

                              <span className="checkmark"></span>
                              <div className="color" style={{
                                width: 20,
                                borderRadius: 5,
                                background: legend.color ? legend.color : "#444",
                                minHeight: legend.size ? "unset" : 20,
                                height: legend.size ? parseInt(legend.size) : 20
                              }}></div>
                              <div className="label">
                                {legend.title} <br />
                                {legend.count}
                              </div>
                            </label>
                          );
                        })
                      }
                    </div>
                  ) : (null)
                }

                {
                  (legendLabel.length > 0) ? (
                    <div className="legend-label-container">
                      {/* <div className="head-label">ความเร็วเฉลี่ย</div> */}
                      <div className="head-label">จำนวนเที่ยววิ่ง/วัน</div>
                      {
                        legendLabel.map((label, index) => {
                          return (
                            <div className="legend" key={index}>
                              <div className="color" style={{
                                background: label.color ? label.color : "#444",
                                minHeight: label.size ? "unset" : 20,
                                height: label.size ? parseInt(label.size) : 20
                              }}></div>
                              <div className="text">{label.title}</div>
                            </div>
                          );
                        })
                      }
                    </div>
                  ) : (null)
                }


              </div>
            </InsaneCollapseContent>

            <InsaneCollapseContent title="DETAIL" type="detail" color="#34495E" style={{ zIndex: 19 }} state={state_detail} stateChange={set_state_detail}>

              <div className="detail-content">

                <div className="title" style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  <h4 style={{ fontWeight: "bolder" }}>รายการ OD</h4>
                  <div style={{ flexGrow: 1 }}></div>
                  <button style={{ color: "#FFF", fontSize: 14, fontWeight: "bolder" }} onClick={() => downloadOD()}>ดาวน์โหลด</button>
                </div>

                <div>
                  <InsaneInputSearch
                    api="/api/getplace"
                    param={{
                      "search": "",
                      "taz": true,
                      "index:": 0,
                      "count": 0
                    }}
                    type="text"
                    label="เลือกจุดต้นทาง"
                    attDisplay="name"
                    attValue="place_id"
                    value={search_location_start}
                    change={set_search_location_start}
                    placeholder="ค้นหา.."
                  ></InsaneInputSearch>

                  <InsaneInputSearch
                    api="/api/getplace"
                    param={{
                      "search": "",
                      "taz": true,
                      "index:": 0,
                      "count": 0
                    }}
                    type="text"
                    label="เลือกจุดปลายทาง"
                    attDisplay="name"
                    attValue="place_id"
                    value={search_location_stop}
                    change={set_search_location_stop}
                    placeholder="ค้นหา.."
                  ></InsaneInputSearch>

                  <button style={{ color: "#FFF", width: "100%" }} type="submit" onClick={() => getOd()}>ค้นหา</button>

                </div>
                <div className="content-table">

                  <div className="head-table" style={{ background: "#ccc" }}>
                    <div className="field">ต้นทาง</div>
                    <div className="field">ปลายทาง</div>
                    <div className="field" style={{ flexGrow: "unset", flexBasis: "unset", width: 60 }}>จำนวนรถ<br />({getUnitTimeSelected()})</div>
                    <div className="field" style={{ flexGrow: "unset", flexBasis: "unset", width: 60 }}>ระยะเวลาเดินทาง<br />(นาที)</div>
                  </div>

                  {DATATABLE.map((list, index) => {
                    return (
                      <div
                        key={index}
                        className="list-table"
                        style={{
                          height: "unset",
                          background: ((list === SELECTED_OD) ? "#0dcaf0" : "")
                        }}
                        onClick={() => {
                          getOdLink(list.o_place_id, list.d_place_id);
                          SET_SELECTED_OD(list);
                        }}>
                        <div className="field">{list.o_name}</div>
                        <div className="field">{list.d_name}</div>
                        <div className="field" style={{ flexGrow: "unset", flexBasis: "unset", width: 60 }}>{parseFloat(list.total_vehicle).toFixed(0)}</div>
                        <div className="field" style={{ flexGrow: "unset", flexBasis: "unset", width: 60 }}>{parseFloat(list.avg_traveltime_m).toFixed(0)}</div>
                      </div>
                    );
                  })}
                </div>

              </div>
            </InsaneCollapseContent>

            <div className="content">

              {
                loadingMap ? (

                  <div
                    className="main-content"
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <Spinner></Spinner>
                    <div style={{ color: "#000" }}>กำลังโหลด</div>
                  </div>
                ) : (

                  <>
                    <div className="display-control-contianer">

                      <div className="contianer" onClick={() => (state_filter) ? set_state_filter(false) : set_state_filter(true)}>
                        <div className="title">{search_date}</div>
                        <div className="title">{getDisplayFilter("peak_logistics", "title", "id", search_time)}</div>
                        <div className="title">{getDisplayFilter("filter_province", "title", "id", search_province)}</div>
                      </div>

                      <div className="contianer control-mobile">
                        {/* <button onClick={() => (state_filter) ? set_state_filter(false) : set_state_filter(true)}>Filter</button> */}
                        <button onClick={() => (state_detail) ? set_state_detail(false) : set_state_detail(true)}>Detail</button>
                      </div>

                    </div>

                    <OpenStreetMap
                      pinmap={PIN_MAP}
                      polygon={POLYGON_MAP}
                      polyline={POLYLINE_MAP}
                      circle={CIRCLE_MAP}
                      fitBoundsRef={fitBounds_MAP_ref}
                      center={mapSettingDefault.center}
                      zoom={mapSettingDefault.zoom}
                    />
                  </>
                )
              }

            </div>

            <div className="content-mobile"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
