
import './App.css';
import React, { Suspense, useState } from 'react';
import {
  // Navigate,
  useRoutes,
} from "react-router-dom";

import Spinner from "./component/spinner";
import { HttpRequest } from "./service/HttpRequest";
import { getRoutes } from "./routing/routes";

import {
  useGlobalContext,
  // GlobalContextProvider,
  setConfigApp,
  setEnum,
  setAuthentication
} from "./service/GlobalContext";


import { HintContent } from "./component/insane-hint-info";

const getConfig = () => {
  const Request = new Promise((resolve, reject) => {
    fetch(`${process.env.PUBLIC_URL}/config.json`)
      .then(res => res.json())
      .then(config => {
        // console.log(config);
        resolve(config)

      }).catch(err => { console.log(err, ' error') });
  });

  return Request;
}

async function getEnum() {

  return await HttpRequest("/api/getenum", {}).then((data) => {
    console.log("HttpRequest /api/getenum:", data);
    if (data.result === "OK") {
      return data.enum;
    } else {
      return null
    }
  });
}

async function checkSession() {
  return await HttpRequest("/api/checksession", {}).then((data) => {
    // console.log("HttpRequest /api/checksession:", data);

    if (data.result === "OK") {
      if (data.session.logout === false) {
        return {
          token: data.session.sessiontoken,
          user: data.user,
          permission: data.permission
        }
      } else {
        return {
          token: "",
          user: "",
          permission: data.permission
        }
      }
    } else {
      return {
        token: "",
        user: "",
        permission: data.permission
      }
    }

  });
}

// const MainRoute = () => {

//   const [loading, setLoading] = React.useState(false);
//   const [globalState, setGlobalState] = useGlobalContext();

//   const [dropdownReport, setDropdownReport] = React.useState([]);

//   let routeList = [
//     // { path: "/dashboard", name: "dashboard", displayName: "DASHBOARD", element: <DashboardPage />, enable: true },
//     // { path: "/map", name: "map", displayName: "MAP", element: <MapPage />, enable: true },
//     // { path: "/table", name: "table", displayName: "OD TABLE", element: <TablePage />, enable: true },
//     { path: "/traffic", name: "traffic", displayName: "TRAFFIC", element: <TrafficPage />, enable: true },
//     { path: "/safety", name: "safety", displayName: "SAFETY", element: <SafetyPage />, enable: true },
//     { path: "/logistics", name: "logistics", displayName: "LOGISTICS", element: <LogisticPage />, enable: true },
//     { path: "/report", name: "report", displayName: "REPORT", element: <ReportPage />, enable: false },
//   ]

//   // let dropdownRouteList = [
//   //   {
//   //     name: "report", displayName: "REPORT", enable: true, routeList: [
//   //       { name: "report_traffic_hour", displayName: "ปริมาณจราจรรายชั่วโมง", enable: true },
//   //       { name: "report_traffic_week", displayName: "ปริมาณจราจรต่อวันรายสัปดาห์", enable: true },
//   //       { name: "report_traffic_month", displayName: "ปริมาณจราจรต่อวันรายเดือน", enable: true },
//   //       { name: "report_traffic_year", displayName: "ปริมาณจราจรต่อวันรายปี", enable: true },
//   //     ]
//   //   },
//   // ]

//   let menuList = [];

//   let PrivateRoutes = [
//     { path: "*", element: <Navigate replace to="/traffic" /> },
//     { path: "/login", element: <Navigate replace to="/traffic" /> },
//   ]

//   let PublicRoutes = [
//     { path: "*", element: <Navigate replace to="/login" /> },
//     { path: "/login", element: <LoginPage /> }
//   ]

//   for (let i = 0; i < routeList.length; i++) {
//     const route = routeList[i];
//     if (route.enable === true) {
//       menuList.push({ path: route.path, name: route.name, displayName: route.displayName });
//     }
//     PrivateRoutes.push({ path: route.path, element: route.element });
//     PublicRoutes.push({ path: route.path, element: <Navigate replace to="/login" /> });
//   }

//   const routeUse = globalState.auth ? PrivateRoutes : PublicRoutes;
//   const IntitailRoute = useRoutes(routeUse);

//   async function getEnum() {
//     HttpRequest("/api/getenum", {}).then((data) => {
//       console.log("HttpRequest /api/getenum:", data);
//       if (data.result === "OK") {
//         setEnum(setGlobalState, data.enum);

//         let _dropdown_report = [
//           {
//             name: "report", displayName: "REPORT", path: "/report", enable: true, routeList: []
//           }
//         ];
//         for (let i = 0; i < data.enum.report_type.length; i++) {
//           const _report = data.enum.report_type[i];

//           _dropdown_report[0].routeList.push(
//             {
//               path: ("/report?report_id=" + _report.id),
//               name: ("REPORT_" + _report.id),
//               displayName: (_report.title),
//               enable: true
//             }
//           )
//         }

//         // console.log(_dropdown_report);

//         setDropdownReport(_dropdown_report);
//       }

//       // setLoading(false);
//     });
//   }

//   React.useEffect(() => {
//     const controller = new AbortController();

//     // console.log("PrivateRoutes", PrivateRoutes);
//     // console.log("PublicRoutes", PrivateRoutes);

//     async function onSetConfigApp() {
//       let config_app = await getConfig();
//       setConfigApp(setGlobalState, config_app);

//       await getEnum();
//     }

//     onSetConfigApp();

//     return () => {
//       controller.abort();
//     };
//   }, []);

//   React.useEffect(() => {
//     const controller = new AbortController();

//     if (globalState.auth) {
//       console.log(globalState.auth);
//     }

//     return () => {
//       controller.abort();
//     };
//   }, [globalState.auth]);

//   return (
//     loading ? (
//       <div
//         className="main-content"
//         style={{ alignItems: "center", justifyContent: "center" }}
//       >
//         <Spinner></Spinner>
//         <div style={{ color: "#000" }}>กำลังโหลด</div>
//       </div>
//     ) : (
//       <div className="main-contrainer">
//         {globalState.auth ? <NavigationBar menuList={menuList} dropdownList={dropdownReport} /> : null}
//         {IntitailRoute}
//       </div>
//     )
//   );

// }

function App() {

  const [globalState, setGlobalState] = useGlobalContext();
  const [Routes, setRoutes] = React.useState(getRoutes());

  // const routes = getRoutes();
  const router = useRoutes(Routes);

  React.useEffect(() => {
    const controller = new AbortController();

    async function IntitialApp() {
      let config_app = await getConfig();
      setConfigApp(setGlobalState, config_app);

      let enum_data = await getEnum();
      setEnum(setGlobalState, enum_data);

      let sesssion_data = await checkSession();
      setAuthentication(setGlobalState, sesssion_data);
    }

    IntitialApp();

    return () => {
      controller.abort();
    };
  }, []);


  React.useEffect(() => {
    const controller = new AbortController();

    let routes = undefined;
    if (globalState.auth) {
      routes = getRoutes(globalState.auth);
    } else {
      routes = getRoutes(null);
    }
    setRoutes(routes);

    return () => {
      controller.abort();
    };
  }, [globalState.auth]);

  // React.useEffect(() => {
  //   const controller = new AbortController();

  //   console.log("Global Context : ", globalState);

  //   return () => {
  //     controller.abort();
  //   };
  // }, [globalState]);

  return (
    <Suspense fallback={
      <div className="main-content" style={{ alignItems: "center", justifyContent: "center" }}>
        <Spinner></Spinner>
        <div style={{ color: "#000" }}>กำลังโหลด</div>
      </div>
    }>
      <div className="main-contrainer">
        <HintContent />
        {router}
      </div>
    </Suspense>
  );

  // return (
  //   // <Router basename='/eec' >
  //   <GlobalContextProvider>
  //     <Router>
  //       <MainRoute />
  //     </Router>
  //   </GlobalContextProvider>
  // );
}

export default App;